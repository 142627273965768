import React from 'react'
import Router from './components/router/routes'
class App extends React.Component {

  render() {

    return (
      <Router />
    );
  }
}

export default App;
