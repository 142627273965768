import React from 'react';
import { useState, useEffect } from 'react';
import Axios from '../../middleware/axios';

const OrderDetails = ({ APIData }) => {
    const OrderDetail = () => {
        if (APIData && APIData.orders) {
            return APIData.orders.map((el, i) => {
                return (
                    <li className="col-md-4" key={i}>
                        <figure className="itemside mb-3">
                            <div className="aside"><img src={`https://www.bridalwearonline.com/files/${el.imageUrl}`} className="img-sm border" /></div>
                            <figcaption className="info align-self-center">
                                <p className="title">{el.title}<br /> {el.brand}</p> <span className="text-muted">{el.price} </span>
                                {el.size? <span className="text-muted">{el.size} </span>:<span/>}
                            </figcaption>
                        </figure>
                    </li>
                )
            })
        }

        else {
            return (<div />)
        }
    }

    const [status, setStatus] = useState('');

    useEffect(() => {
        if (APIData && APIData.orders) {
            setStatus(APIData.status);
        }
    });

    const options = ["pending", "delivered"];
    const [selectedOption, setSelectedOption] = useState(status);
    const [orderId, setOrderId] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');



    const onOptionClicked = value => () => {
        setSelectedOption(value);
    };

    const updateAPIData = (e) => {
        const changeStatus = { status: 'delivered' };
        Axios.put(`/order/${APIData._id}`,
            changeStatus
        ).then((response) => {
            setErrorMessage(response.data.message);
            window.location.reload(false)
        }).catch((error) => {
            setErrorMessage(error.data.message);
        });
    }


    return (
        <div className="container orderdetailContainer">
            <article className="card">
                <header className="card-header"> My Order </header>
                <div className="card-body">
                    <h6>Order ID: {APIData._id}</h6>
                    <article className="card">
                        <div className="card-body row">
                            <div className="col"> <strong>Delivery Address:</strong> <br />{APIData.deliveryAddress} </div>
                            <div className="col"> <strong>Full Name:</strong> <br /> <i className="fa fa-phone"></i> {APIData.fullName} </div>
                            <div className="col"> <strong>Phone Number:</strong> <br /> <i className="fa fa-phone"></i> {APIData.phoneNumber} </div>
                            <div className="col"> <strong>Status:</strong> <br /> {APIData.status} </div>
                            <div className="col"> <strong>Order date:</strong> <br /> {new Date(APIData.createdAt).toLocaleDateString()} </div>
                            <div className="col"> <strong>Total Price:</strong> <br /> {APIData.totalPrice} </div>
                            <div className="col"> <strong>Email:</strong> <br /> {APIData.email ? APIData.email : 'Not Provided'} </div>
                        </div>
                    </article>
                    <hr />
                    <ul className="row">
                        <OrderDetail />
                    </ul>
                    <hr />
                    <form>
                        <div className="form-group">
                            <label>Change Order Status</label>
                            <select className='form-control' onChange={onOptionClicked}>
                                {options.map(option => (
                                    option == status ?
                                        <option value={option} selected key={Math.random()}>
                                            {option}
                                        </option>
                                        :
                                        <option value={option} key={Math.random()}>
                                            {option}
                                        </option>
                                ))}
                            </select>
                        </div>
                        <span className='customMessage'>{errorMessage}</span>
                        <hr />
                        <button type='button' className='btn btn-primary' onClick={updateAPIData}>Submit</button>
                    </form>
                    <hr /> <a href="/admin/orders" className="btn btn-warning" data-abc="true"> <i className="fa fa-chevron-left"></i> Back to orders</a>
                </div>
            </article>
        </div>
    )

}

export default OrderDetails