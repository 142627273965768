import React from 'react';
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazy-load'
import variable from '../helpers/variable';
const ProductRow = ({ product, modifyWishList, location }) => {
  let imgUrl = undefined
  if (product.pictures) {
    imgUrl = product.pictures[0].pictureUrl;
    if (imgUrl == undefined) {
      imgUrl = '/img/no-image.jpg'
    }
  }
  return (
    // <div className="col-lg-3 col-sm-3" style={{ border: "2px solid red" }}>
    <div className="grid-item">
      <div className="product-item" >
        <div className="pi-pic item-image">
          <LazyLoad>
            <img width={'100%'} height={'100%'} style={{ objectFit: 'cover' }} src={`${variable.url}${imgUrl}`} alt="" />
          </LazyLoad>
          <div className="icon">
            <i className="icon_heart_alt" value={product._id} onClick={(e) => { modifyWishList(product._id, location) }} ></i>
          </div>
          <ul>
            <li className="w-icon active"><Link to="#"><i className="icon_bag_alt"></i></Link></li>
            <li className="quick-view"><a href={"/product/" + product._id}>+ Quick View</a></li>
            <li className="w-icon"><Link to="#"><i className="fa fa-random"></i></Link></li>
          </ul>
        </div>
        <div className="pi-text">
          <div className="catagory-name">{product.type}</div>
          <a href={"/product/" + product._id} >
            <span>{product.title.length > 15 ? product.title.slice(0, 17) + "..." : product.title}</span>
          </a>
          <div className="product-price">
            Rs.{product.price}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductRow;