import React, { Component } from 'react';
import Axios from '../../middleware/axios';
import history from '../../middleware/history';

class Admin extends Component {
    constructor(props) {
        super(props)
        this.state = {
            credential: {
                email: '',
                password: '',
                type: 'Admin'
            },
            errorMessage: '',
            successMessage: '',
        }
    }
    componentWillMount() {
        const getToken = localStorage.getItem("login")
        if (getToken) {
            history.push("/admin/dashboard")
            window.location.reload(false)

        }
    }
    onChangeHandler = (event) => {
        this.setState({
            errorMessage: ''
        })
        const credential = this.state.credential
        credential[event.target.name] = event.target.value
        this.setState({
            credential
        })
    }
    signIn = (event) => {
        try {
            event.preventDefault()
            if (!this.state.credential.email.trim() || !this.state.credential.password.trim()) {
                this.setState({
                    errorMessage: 'Email address and password fields are required'
                })
                return
            }
            Axios
                .post('/login', this.state.credential)
                .then((response) => {
                    localStorage.setItem('login', response.data.data.accessToken)
                    history.push('/admin/dashboard');
                    window.location.reload();
                }).catch((error) => {
                    this.setState({ errorMessage: error.data.message })
                })
        } catch (error) {
            this.setState({ errorMessage: 'Whoops! something went wrong' })
        }
    }
    render() {
        return (
            <div>
                <header id="header">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h1 className="text-center">Bridalwear<small> Login</small></h1>
                            </div>
                        </div>
                    </div>
                </header>

                <section id="main">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4 col-md-offset-4">
                                <form id="login" className="well">
                                    <div className="form-group">
                                        <label>Email</label>
                                        <input type="text" name='email' className="form-control" placeholder="Enter Email" onChange={this.onChangeHandler} />
                                    </div>
                                    <div className="form-group">
                                        <label>Password</label>
                                        <input type="password" name='password' className="form-control" placeholder="Enter password" onChange={this.onChangeHandler} />
                                    </div>
                                    <div style={{ color: 'red' }}>{this.state.errorMessage}</div>
                                    <button type="button" className="btn btn-primary btn-block" onClick={this.signIn}>Login</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
export default Admin