import React, { useEffect } from 'react';
import Header from '../Header';
import Footer from '../Footer';
import Nav from '../nav';
import AddStock from './addStock';
import history from '../../../helpers/history'


export default function Create(props) {
	useEffect(() => {
		const getToken = localStorage.getItem("login")
		if (!getToken) {
			history.push("/admin/login")
			window.location.reload(false)

		}
	}, [])
	return (
		<div>
			<Header />
			<section id="main">
				<div className="container">
					<div className="row">
						<Nav />
						<div className="col-md-9">
							<div className="panel panel-default">
								<div className="panel-heading main-color-bg">
									<h3 className="panel-title">Stock</h3>
								</div>
								<div className="panel-body">
									<AddStock />
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<Footer />
		</div>
	)
}
