import React, { Component, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { Dropdown } from 'react-bootstrap'
import history from '../middleware/history';
import axios from '../middleware/axios';
import { isMobile } from 'react-device-detect'
import variable from '../helpers/variable';

class Navigation extends Component {
    constructor(props) {
        super(props)
    }
    state = {
        buyItems: [],
        subTotal: 0,
        totalItems: 0,
        type: '',
        showMenu: false

    }
    componentDidMount() {
        try {
            this.getData()
        } catch (error) { }

    }


    getData = () => {
        try {
            let getFromLocalStroage = localStorage.getItem('orders')
            let getFromLocalStroageSubTotal = localStorage.getItem('subTotal')

            this.setState({ buyItems: JSON.parse(getFromLocalStroage) })
            this.setState({ subTotal: JSON.parse(getFromLocalStroageSubTotal) })

            if (getFromLocalStroage) {
                this.setState({ totalItems: JSON.parse(getFromLocalStroageSubTotal).length })
            }
        } catch (error) { }
    }


    onSubmit = (event) => {
        try {
            event.preventDefault()
            this.props.getData(this.state.type)
        } catch (error) { }
    }

    onChange = (event) => {
        try {
            const letters = /^[a-zA-Z ]*$/;
            if (event.target.value.match(letters)) {

                this.setState({
                    type: event.target.value
                })
            }

        } catch (error) { }
    }

    onLogout = (event) => {
        try {
            event.preventDefault()
            axios
                .post('/logout')
                .then((response) => {
                    localStorage.removeItem('login');
                    history.push('/login');
                    window.location.reload(false)
                })


        } catch (error) {

        }
    }

    navigationRoute = () => {
        window.location.reload(false);
    }


    render() {

        const buyItems = this.props.buyItems & length ? this.props.buyItems : this.state.buyItems;
        const CartItems = ({ item }) => {
            return (
                <table>
                    <tr>
                        <tbody>
                            <td className="si-pic"><img src={`${variable.url}${item.imageUrl}`} style={{ width: 100, height: 100 }} alt="" /></td>
                            <td className="si-text">
                                <div className="product-selected">
                                    <p>{item.price} x {item.quantity}</p>
                                    <h6>{item.title}</h6>
                                </div>
                            </td>
                        </tbody>
                    </tr>
                </table>
            )
        }

        return (
            <header className="header-section">
                <div className="container">
                    <div className="inner-header">
                        <div className="row">
                            <div className="col-lg-4 col-md-4">
                                <div className="logo" onClick={this.navigationRoute}>
                                    <Link to="/">
                                        <img
                                            src={'/img/sm-logo2.png'} alt="bridalwearonline" />
                                    </Link>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-3" style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                                {this.props.search && <div className="search-wrap search-wrap-2 search-width-2">
                                    <form className="search-form" onSubmit={this.onSubmit} >
                                        <input type="text" name="type" onChange={this.onChange} value={this.state.type} placeholder="Search products..." />
                                    </form>
                                </div>
                                }
                            </div>
                            <div className="col-lg-3 text-right col-md-3" style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                                <ul className="nav-right" style={{ margin: 0 }}>
                                    <li className="heart-icon">
                                        <Link to="/wish-list" >
                                            <i className="icon_heart_alt"></i>
                                        </Link>
                                    </li>

                                    <li className="cart-icon">
                                        <Link to="#">
                                            <i className="icon_bag_alt"></i>
                                            <span>{this.props.totalItems ? this.props.totalItems : this.state.totalItems}</span>
                                        </Link>
                                        <div className="cart-hover">
                                            <div className="select-items">
                                                <table>
                                                    <tbody>
                                                        {
                                                            this.state.buyItems == null
                                                                ?
                                                                <span>Empty Shopping Cart</span>
                                                                :
                                                                buyItems.map((item, i) => (
                                                                    <CartItems key={i} item={item} />
                                                                ))
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="select-total">
                                                <span>total:</span>
                                                <h5>{this.props.subTotal ? this.props.subTotal : this.state.subTotal}</h5>
                                            </div>
                                            <div className="select-button">
                                                <Link to="/Shoppingcart" className="primary-btn view-card">VIEW CART</Link>
                                                <Link to="/checkout" className="primary-btn checkout-btn">CHECK OUT</Link>
                                            </div>

                                        </div>
                                    </li>
                                    <li className="cart-price">{this.state.subTotal}</li>

                                    <li className="cart-icon">
                                        {localStorage.getItem('login') ?
                                            <Dropdown>
                                                <Dropdown.Toggle variant="default" size="sm" id="dropdown-basic">
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item href="/me">Profile</Dropdown.Item>
                                                    <Dropdown.Item onClick={this.onLogout} href="#">Logout</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            :

                                            <Link to="/login">
                                                <i color={"red"} className="fa fa-user"></i>
                                            </Link>
                                        }

                                    </li>

                                </ul>

                            </div>
                        </div>
                    </div>

                </div>

                <div className="nav-item">

                    <div className="container">
                        {isMobile ?

                            <div id="mobile-menu-wrap">
                                <div className="slicknav_menu">
                                    <a href="#" aria-haspopup="true" role="button" onClick={() => { this.setState({ showMenu: !this.state.showMenu }) }} tabindex="0" className="slicknav_btn slicknav_collapsed"><span className="slicknav_menutxt">MENU</span><span className="slicknav_icon"><span className="slicknav_icon-bar"></span><span className="slicknav_icon-bar"></span><span className="slicknav_icon-bar"></span></span></a>
                                    {this.state.showMenu ?
                                        <nav className="slicknav_nav slicknav_hidden" aria-hidden="false" role="menu" >
                                            <ul>
                                                <li onClick={this.navigationRoute}><Link to="/kashish">kashesh beauty products</Link></li>
                                                <li onClick={this.navigationRoute}><Link to="/on-demand">On demand</Link></li>
                                                <li onClick={this.navigationRoute}><Link to="/summer-collection">Summer collection</Link>
                                                    <ul className="dropdown">
                                                        <li onClick={this.navigationRoute}><Link to="/lawn-unstitch">Lawn unstitch</Link></li>
                                                    </ul>
                                                </li>
                                                <li onClick={this.navigationRoute}><Link to="/sale">Sale</Link>
                                                    <ul className="dropdown">
                                                        <li onClick={this.navigationRoute}><Link to="/shoes">Shoes</Link></li>
                                                        <li onClick={this.navigationRoute}><Link to="/bags">Bags</Link></li>
                                                        <li onClick={this.navigationRoute}><Link to="/accessories">Accessories</Link></li>
                                                        <li onClick={this.navigationRoute}><Link to="/luxury-sale">Luxury sale</Link></li>
                                                        <li onClick={this.navigationRoute}><Link to="/winter-sale">Winter sale</Link></li>
                                                    </ul>
                                                </li>
                                                <li onClick={this.navigationRoute}><Link to="/new-arrival">New arrival</Link></li>
                                                <li onClick={this.navigationRoute}><Link to="/luxury-collection">Luxury collection</Link>
                                                    <ul className="dropdown">
                                                        <li onClick={this.navigationRoute}><Link to="/saries">Saries</Link></li>
                                                        <li onClick={this.navigationRoute}><Link to="/lehnga-dresses">Lehnga dresses</Link></li>
                                                        <li onClick={this.navigationRoute}><Link to="/luxury-party-wear">Luxury party wear</Link></li>
                                                    </ul>
                                                </li>
                                                <li onClick={this.navigationRoute}><Link to="/original">Orginal</Link></li>

                                                <li onClick={this.navigationRoute}><Link to="/winter-collection">Winter collection</Link>
                                                    <ul className="dropdown">
                                                        <li onClick={this.navigationRoute}><Link to="/velvet">Velvet</Link></li>
                                                        <li onClick={this.navigationRoute}><Link to="/cotton">Cotton</Link></li>
                                                        <li onClick={this.navigationRoute}><Link to="/marina">Marina</Link></li>
                                                        <li onClick={this.navigationRoute}><Link to="/linen">Linen</Link></li>
                                                    </ul>
                                                </li>
                                                <li onClick={this.navigationRoute}><Link to="/ready-to-wear">Ready to wear</Link></li>
                                            </ul>

                                        </nav>
                                        : <span />
                                    }

                                </div>

                            </div>
                            :
                            <nav className="nav-menu mobile-menu">
                                <ul style={{ marginBottom: 0 }}>
                                    <li onClick={this.navigationRoute}><Link to="/kashish">kashesh beauty products</Link></li>
                                    <li onClick={this.navigationRoute}><Link to="/on-demand">On demand</Link></li>
                                    <li onClick={this.navigationRoute}><Link to="/summer-collection">Summer collection</Link>
                                        <ul className="dropdown">
                                            <li onClick={this.navigationRoute}><Link to="/lawn-unstitch">Lawn unstitch</Link></li>
                                        </ul>
                                    </li>
                                    <li onClick={this.navigationRoute}><Link to="/sale">Sale</Link>
                                        <ul className="dropdown">
                                            <li onClick={this.navigationRoute}><Link to="/shoes">Shoes</Link></li>
                                            <li onClick={this.navigationRoute}><Link to="/bags">Bags</Link></li>
                                            <li onClick={this.navigationRoute}><Link to="/accessories">Accessories</Link></li>
                                            <li onClick={this.navigationRoute}><Link to="/luxury-sale">Luxury sale</Link></li>
                                            <li onClick={this.navigationRoute}><Link to="/winter-sale">Winter sale</Link></li>
                                        </ul>
                                    </li>
                                    <li onClick={this.navigationRoute}><Link to="/new-arrival">New arrival</Link></li>
                                    <li onClick={this.navigationRoute}><Link to="/luxury-collection">Luxury collection</Link>
                                        <ul className="dropdown">
                                            <li onClick={this.navigationRoute}><Link to="/saries">Saries</Link></li>
                                            <li onClick={this.navigationRoute}><Link to="/lehnga-dresses">Lehnga dresses</Link></li>
                                            <li onClick={this.navigationRoute}><Link to="/luxury-party-wear">Luxury party wear</Link></li>
                                        </ul>
                                    </li>
                                    <li onClick={this.navigationRoute}><Link to="/original">Orginal</Link></li>

                                    <li onClick={this.navigationRoute}><Link to="/winter-collection">Winter collection</Link>
                                        <ul className="dropdown">
                                            <li onClick={this.navigationRoute}><Link to="/velvet">Velvet</Link></li>
                                            <li onClick={this.navigationRoute}><Link to="/cotton">Cotton</Link></li>
                                            <li onClick={this.navigationRoute}><Link to="/marina">Marina</Link></li>
                                            <li onClick={this.navigationRoute}><Link to="/linen">Linen</Link></li>
                                        </ul>
                                    </li>
                                    <li onClick={this.navigationRoute}><Link to="/ready-to-wear">Ready to wear</Link></li>
                                </ul>


                            </nav>

                        }
                    </div>
                </div>
            </header >
        )
    }
}
export default Navigation