import React, { Component } from 'react';
import Header from '../admin/Header';
import Footer from '../admin/Footer';
import Nav from '../admin/nav';
import Axios from '../../middleware/axios';

class Admin extends Component {
	constructor(props) {
		super(props)

	}
	state = {
		usersCount: '0',
		productsCount: '0',
		ordersCount: '0',
		users: [],
	}

	componentDidMount() {

		this.getCounts()
		this.getUsers()
	}

	getCounts = () => {
		Axios
			.get(`/counts`)
			.then((response) => {
				this.setState({
					usersCount: response.data.data.userCount,
					ordersCount: response.data.data.orderCount,
					productsCount: response.data.data.stockCount
				})

			});
	}
	getUsers = () => {
		Axios
			.get(`/users?size=5&page=1`)
			.then((response) => {
				this.setState({
					users: response.data.data
				})

			});
	}

	render() {
		return (
			<div>
				<Header />

				<section id="main">
					<div className="container">
						<div className="row">
							<Nav />
							<div className="col-md-9">
								<div className="panel panel-default">
									<div className="panel-heading main-color-bg">
										<h3 className="panel-title">Bridalwear Overview</h3>
									</div>
									<div className="panel-body">
										<div className="col-md-3">
											<div className="well dash-box">
												<h2><span className="glyphicon glyphicon-user" aria-hidden="true"></span> {this.state.usersCount}</h2>
												<h4>Users</h4>
											</div>
										</div>
										<div className="col-md-3">
											<div className="well dash-box">
												<h2><span className="glyphicon glyphicon-list-alt" aria-hidden="true"></span> {this.state.ordersCount}</h2>
												<h4>Orders</h4>
											</div>
										</div>
										<div className="col-md-3">
											<div className="well dash-box">
												<h2><span className="glyphicon glyphicon-pencil" aria-hidden="true"></span> {this.state.productsCount}</h2>
												<h4>Stocks</h4>
											</div>
										</div>

									</div>
								</div>


								<div className="panel panel-default">
									<div className="panel-heading">
										<h3 className="panel-title">Latest Users</h3>
									</div>
									<div className="panel-body">
										<table>
											<tbody className="table table-striped table-hover">
												<tr>
													<th>Name</th>
													<th>Email</th>
													<th>Joined</th>
													<th>City</th>
													<th>Country</th>
												</tr>
												{
													this.state.users.map((user, i) => (
														<tr key={i}>
															<td>{user.fullName}</td>
															<td>{user.email}</td>
															<td>{new Date(user.createdAt).toLocaleString()}</td>
															<td>{user.city}</td>
															<td>{user.country}</td>

														</tr>
													))
												}

											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				<Footer />
			</div>
		)
	}
}
export default Admin