import React, { Component } from 'react'
import axios from '../middleware/axios';
import Navigation from './Navigation';
import { ToastContainer, toast } from 'react-toastify';
import Webfooter from './Footer';
// import whatsAppWidget from '../helpers/whatsapp-widget'
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
import moment from 'moment-timezone'
import history from '../middleware/history';

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            credential: {
                email: '',
                shippingAddress: '',
                phoneNumber: '',
                fullName: '',
                city: '',
                country: '',
            },
            errorMessage2: '',
            userId: '',
            buttonEnable: true,
            errorMessage: '',
            successMessage: '',
            total: 0
        }
    }
    //Asia/Karachi
    componentDidMount() {
        // whatsAppWidget()
        const getToken = localStorage.getItem("login")
        if (getToken) {
            axios.get('/authentic-user').then((response) => {
                console.log(response)
                if (response.data.authentication) {
                    const credential = response.data.user
                    this.setState({ credential, userId: response.data.user._id })
                }

            })
        }
        const total = localStorage.getItem('subTotal')
        this.setState({ total })

    }
    onChangeHandler = (event) => {
        this.setState({
            errorMessage: '',
            errorMessage2: ''
        })
        const credential = this.state.credential
        credential[event.target.name] = event.target.value
        this.setState({
            credential
        })


    }

    checkout = (event) => {
        try {
            event.preventDefault()
            if (!this.state.total) {
                this.setState({
                    errorMessage2: 'Please select atleast one article first to place an order.'
                })
                return
            }

            if (!this.state.credential.shippingAddress.trim() || !this.state.credential.phoneNumber.trim() || !this.state.credential.fullName.trim() || !this.state.credential.city.trim() || !this.state.credential.country.trim()) {
                this.setState({
                    errorMessage: 'All fields are mendatory.'
                })
                return
            }

            if (this.state.buttonEnable) {

                const getFromLocalStroage = localStorage.getItem('orders')
                const getFromLocalStroageSubTotal = localStorage.getItem('subTotal')

                const orders = JSON.parse(getFromLocalStroage)

                const subTotal = JSON.parse(getFromLocalStroageSubTotal)

                this.setState({
                    buttonEnable: false
                })
                const data = {
                    shippingDetails: this.state.credential,
                    orders: orders,
                    total: subTotal,
                    userId: this.state.userId,
                    createdAt: moment().tz("Asia/Karachi").format()
                }

                axios
                    .post('/orders', data)
                    .then((response) => {
                        toast(response.data.message, { type: 'success' });
                        localStorage.removeItem('orders')
                        localStorage.removeItem('subTotal')
                        setTimeout(() => { history.push('/'); window.location.reload() }, 2000)


                    }).catch((error) => {
                        this.setState({ errorMessage: error.data.message })
                    })
                this.setState({
                    buttonEnable: true
                })
            }
        } catch (error) {
        }
    }


    render() {
        return (
            <div>
                <Navigation />

                <section className="checkout-section spad">
                    <div className="container">
                        <div className="alert alert-success" role="alert">
                            <b> Please note, delivery may take 3-5 bussiness days !</b>
                        </div>
                        <form className="checkout-form">
                            <div className="row">
                                <div className="col-lg-6">

                                    <h4>Delivery Details</h4>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <label for="fir">Full Name<span>*</span></label>
                                            <input type="text" name="fullName" onChange={this.onChangeHandler} value={this.state.credential.fullName} id="fir" />
                                        </div>

                                        <div className="col-lg-12">
                                            <label for="street">Street Address<span>*</span></label>
                                            <input type="text" id="street" name="shippingAddress" onChange={this.onChangeHandler} value={this.state.credential.shippingAddress} className="street-first" />
                                        </div>
                                        <div className="col-lg-6">
                                            <label for="email">City<span>*</span></label>
                                            <input type="text" id="email" name="city" onChange={this.onChangeHandler} value={this.state.credential.city} />
                                        </div>
                                        <div className="col-lg-6">
                                            <label for="phone">Country<span>*</span></label>
                                            <input type="text" id="phone" name="country" onChange={this.onChangeHandler} value={this.state.credential.country} />
                                        </div>
                                        <div className="col-lg-6">
                                            <label for="email">Email Address</label>
                                            <input type="text" id="email" name="email" onChange={this.onChangeHandler} value={this.state.credential.email} />
                                        </div>
                                        <div className="col-lg-6">
                                            <label for="phone">Phone<span>*</span></label>
                                            <input type="number" id="phone" name="phoneNumber" onChange={this.onChangeHandler} value={this.state.credential.phoneNumber} />
                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="place-order">
                                        <h4>Pricing</h4>
                                        <div className="order-total">
                                            <ul className="order-table">
                                                <li className="total-price">Total <span>{this.state.total ? this.state.total : '0' + ' PKR'}</span></li>
                                            </ul>

                                            <div className="order-btn">
                                                <button type="button" onClick={this.checkout} className="site-btn place-btn">Place Order</button>
                                            </div>
                                            <div style={{ color: 'red', alignContent: 'center', textAlign: 'center' }}>{this.state.errorMessage2}</div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </form>
                        <ToastContainer />
                        <WhatsAppWidget companyName='Bridalwear' replyTimeText='Typically replies within a day' message={`Hi! How can I help you?`} phoneNumber="+923292060000" />


                    </div>
                </section >
                <Webfooter />
            </div >

        )
    }
}
export default Index