import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../Header';
import Footer from '../Footer';
import Nav from '../nav';
import Axios from '../../../middleware/axios';
import { useState,useEffect } from 'react';

const handleChange = (e) => {
	this.setState({status: e.target.value});
}

const onOptionClicked = value => () => {
	setSelectedOption(value);
};

export default function Read(props) {
	const [APIData, setAPIData] = useState({});
	const [userId, setUserId] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
	const [status, setStatus] = useState("");
	const options = ["pending", "block", "active"];
	const [selectedOption, setSelectedOption] = useState(status);

	useEffect( () => {
        Axios.get(`/user/${props.match.params.id}`)
            .then((response) => {
                setAPIData(response.data.data);
				setStatus(response.data.data.status);
				setUserId(response.data.data._id);
            });
    }, []);

	const updateAPIData = (e) => {
		const changeStatus = { status : 'active' };
		Axios.put(`/users/${userId}`, 
			changeStatus
		).then((response) => {
			setErrorMessage(response.data.message);
		}).catch((error) => {
			setErrorMessage(error.data.message);
		});
	}
	
	return (
		<div>
			<Header />
			<section id="main">
				<div className="container">
					<div className="row">
						<Nav />
						<div className="col-md-9">
							<div className="panel panel-default">
								<div className="panel-heading main-color-bg">
									<h3 className="panel-title">Users</h3>
								</div>
								<div className="panel-body">
									<form>
										<div className="form-group">
											<label htmlFor="exampleInputEmail1">Email address</label>
											<input type="email" className="form-control" value={APIData.email}  aria-describedby="emailHelp" placeholder="Enter email"  readOnly />
											<small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
										</div>
										<div className="form-group">
											<label>Full Name</label>
											<input type="text" className="form-control" value={APIData.fullName}  readOnly />
										</div>
										<div className="form-group">
											<label>Phone Number</label>
											<input type="text" className="form-control" value={APIData.phoneNumber}  readOnly />
										</div>
										<div className="form-group">
											<label>Shipping Address</label>
											<input type="text" className="form-control" value={APIData.shippingAddress}  readOnly />
										</div>
										<div className="form-group">
											<label>Type of Customer</label>
											<input type="text" className="form-control" value={APIData.type} readOnly />
										</div>
 										<div className="form-group">
											<label>Status</label>
											<select className='form-control' onChange={onOptionClicked}>
												{options.map(option => (
													option == status ? 
													<option  value={option} selected  key={Math.random()}>
													{option}
													</option>
													: 
													<option  value={option}  key={Math.random()}>
													{option}
													</option>
												))}
											</select>
										</div>
										<span className='customMessage'>{errorMessage}</span>
										<hr/>
										<button type="button" className="btn btn-primary" onClick={updateAPIData}>Update</button>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>		

			<Footer />
		</div>
	)
}
