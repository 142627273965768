import React, { Component } from 'react';

export default function Nav(props) {
	const url = window.location.href;
	const parts = url.split('/');
	const result = parts.pop();

	return (
		<div className="col-md-3">
			<div className="list-group">

				{
					result == 'dashboard' ? < a href="/admin/dashboard" className='list-group-item active main-color-bg'>
						<span className="glyphicon glyphicon-dashboard" aria-hidden="true"></span> Dashboard
					</a> : < a href="/admin/dashboard" className='list-group-item'>
						<span className="glyphicon glyphicon-dashboard" aria-hidden="true"></span> Dashboard
					</a>
				}

				{
					result == 'orders' ?
						<a href="/admin/orders" className="list-group-item active main-color-bg"><span className="glyphicon glyphicon-list-alt " aria-hidden="true"></span> Orders <span className="badge"></span></a>
						:
						<a href="/admin/orders" className="list-group-item"><span className="glyphicon glyphicon-list-alt" aria-hidden="true"></span> Orders <span className="badge"></span></a>
				}

				{
					result == 'stocks' ?

						<a href="/admin/stocks" className="list-group-item active main-color-bg"><span className="glyphicon glyphicon-pencil" aria-hidden="true"></span> Stocks <span className="badge"></span></a>
						:
						<a href="/admin/stocks" className="list-group-item"><span className="glyphicon glyphicon-pencil" aria-hidden="true"></span> Stocks <span className="badge"></span></a>
				}


				{
					result == 'users' ?

						<a href="/admin/users" className="list-group-item active main-color-bg"><span className="glyphicon glyphicon-user" aria-hidden="true"></span> Users <span className="badge"></span></a>
						:
						<a href="/admin/users" className="list-group-item"><span className="glyphicon glyphicon-user" aria-hidden="true"></span> Users <span className="badge"></span></a>
				}
				{
					result == 'delivery-charges-list' ?
						<a href="/admin/delivery-charge" className="list-group-item active main-color-bg">
							<span className="glyphicon glyphicon-usd" ></span> Delivery Charges
						</a>
						:

						<a href="/admin/delivery-charges-list" className="list-group-item ">
							<span className="glyphicon glyphicon-usd" ></span> Delivery Charges
						</a>
				}
				{
					result == 'settings' ?
						<a href="/admin/settings" className="list-group-item active main-color-bg">
							<span className="glyphicon glyphicon-cog" ></span> Settings
						</a>
						:

						<a href="/admin/settings" className="list-group-item ">
							<span className="glyphicon glyphicon-cog" ></span> Settings
						</a>
				}




			</div>
		</div >
	);

}