import React, { Component } from 'react';
import Axios from '../middleware/axios';
import ProductRow from './ProductRow';
import Navigation from './Navigation';
import Webfooter from './Footer';
import { ToastContainer, toast } from 'react-toastify';
import InfiniteLoader from 'react-infinite-loader'
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
import './style.css'
import { HorizontelScroller } from './horizontal-products';

class Index extends Component {
    constructor(props) {
        super(props)
    }
    state = {
        products: [],
        productSlider: [],
        buttonEnable: false,
        page: 1,
        sliderPage: 1,
        type: '',
        message: '',
        size: 5,
        emptied: false,
        sliderEmptied: false,
        loading: true,
        etag: ''
    }
    componentDidMount() {
        try {
            if (this.props.match.params && this.props.match.params.id) {
                this.setState({
                    etag: this.props.match.params.id
                }, () => {
                    this.getData()
                })
            } else {
                this.getData()
            }
            this.getSlider()
        } catch (error) { }
    }
    getSlider = (value) => {
        if (!this.state.sliderEmptied) {
            let page = this.state.page
            if (value) {
                page = 1
            }
            let path = `/stocks?size=6&page=${this.state.sliderPage}&displayOnSlider=true` //
            Axios
                .get(path)
                .then((response) => {
                    const data = response.data.data
                    const copy = [...this.state.productSlider]
                    let productSlider = this.state.productSlider;
                    if (copy.length) {
                        for (let key in copy) {
                            if (copy[key]._id !== data[key]._id) {
                                copy.push(data[key])
                            }
                        }
                        productSlider = this.state.productSlider.concat(copy)
                    } else {
                        productSlider = this.state.productSlider.concat(data)
                    }
                    this.setState({ productSlider, buttonEnable: true, page: this.state.sliderPage + 1, loading: false })
                    if (!response.data.data.length) {

                        this.setState({ sliderEmptied: true })

                    }
                })
        }
    }
    getData = (value) => {
        if (!this.state.emptied) {
            let page = this.state.page
            if (value) {
                page = 1
            }
            let path = `/stocks?size=${this.state.size}&page=${page}&type=${this.state.type}&etag=${this.state.etag}` //
            Axios
                .get(path)
                .then((response) => {
                    const data = response.data.data
                    if (data.length) {
                        this.setState({
                            products: this.state.products.concat(data)
                        })
                    }
                    this.setState({ buttonEnable: true, page: this.state.page + 1, loading: false })
                    if (!response.data.data.length) {

                        this.setState({ emptied: true })

                    }
                })
        }
    }
    seacher = (value) => {
        try {
            this.setState(prevState => ({
                prevState,
                page: 1,
                products: [],
                type: value,
                emptied: false,
                loading: true
            }), () => {

                this.getData(true)
            })

        } catch (error) {

        }

    }
    addToWishList = (event, key) => {
        try {
            if (this.state.buttonEnable) {
                this.setState({
                    buttonEnable: false
                })
                Axios
                    .post('/wishlist', { stockId: event })
                    .then((response) => {
                        toast(response.data.message, { type: 'success', position: 'top-center' })
                        thids.setState({ buttonEnable: true })

                    }).catch((error) => {
                        toast(error.data.message, { type: 'error', position: 'top-center' })

                    })
            }
        } catch (error) {
            toast(error.message, { type: 'error', position: 'top-center' })

        }
    }
    render() {
        const { products, productSlider } = this.state;
        return (
            <div>

                {/* <!-- Header Section Begin --> */}
                <Navigation getData={this.seacher} search={true} />
                {/* banner carasol */}
                <HorizontelScroller productSlider={productSlider} />
                {this.state.loading ?
                    <div id="preloder" >
                        <img className='loader' src='/icons/shipping-cart.gif' style={{ width: 100, height: 100 }} />
                    </div>
                    : <span />
                }

                {/* <!-- Header End --> */}

                <link rel="stylesheet" type="text/css" href="https://cdn.jsdelivr.net/gh/kenwheeler/slick@1.8.1/slick/slick-theme.css" />
                <link rel="stylesheet" type="text/css" href="https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.css" />

                <div className="grid-container">
                    {
                        this.state.products.length ?
                            products.map((product, i) => (
                                <ProductRow product={product} key={i} modifyWishList={this.addToWishList} />
                            ))
                            :
                            <div style={{ textAlign: 'center' }}>
                                <img src='/img/no-product.png' style={{ minHeight: '300px', minWidth: '300px' }} className="no-product" />
                            </div>
                    }
                </div>
                <div className='more-contnet'>
                    < InfiniteLoader onVisited={this.getData} />
                </div>
                <ToastContainer />
                <WhatsAppWidget companyName='Bridalwear' replyTimeText='Typically replies within a day' message={`Hi! How can I help you?`} phoneNumber="+923292060000" />


                {/* <!-- Footer Section Begin --> */}
                <Webfooter />
                {/* <!-- Footer Section End --> */}

            </div>
        )
    }
}
export default Index