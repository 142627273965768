import React, { Component } from 'react'
import Navigation from './Navigation';
import { Link } from 'react-router-dom'
import axios from '../middleware/axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import history from '../middleware/history'
import Webfooter from './Footer';


class Index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            credential: {
                email: '',
                password: '',
                confirmPassword: '',
                shippingAddress: '',
                phoneNumber: '',
                fullName: '',
                city: '',
                country: ''
            },
            buttonEnable: true,
            errorMessage: '',
            successMessage: ''
        }

    }
    componentWillMount() {
        const getToken = localStorage.getItem("login")
        if (getToken) {
            history.push("/")
            window.location.reload(false)

        }
    }
    onChangeHandler = (event) => {
        this.setState({
            errorMessage: ''
        })
        const credential = this.state.credential
        credential[event.target.name] = event.target.value
        this.setState({
            credential
        })

        if (event.target.name == 'confirmPassword') {
            if (this.state.credential.password != this.state.credential.confirmPassword) {
                this.setState({
                    errorMessage: 'Password and confirm password do not match.'
                })
            } else {
                this.setState({
                    successMessage: 'Password and confirm password match.'
                })
            }
        }
    }
    register = (event) => {
        try {
            event.preventDefault()

            if (!this.state.credential.email.trim() || !this.state.credential.shippingAddress.trim() || !this.state.credential.phoneNumber.trim() || !this.state.credential.fullName.trim()) {
                this.setState({
                    errorMessage: 'All fields are mendatory.'
                })
                return
            }

            if (this.state.credential.password.trim() != this.state.credential.confirmPassword.trim()) {
                this.setState({
                    errorMessage: 'Password and confirm password do not match.'
                })
                return
            }
            if (this.state.credential.password.trim().length < 8) {
                this.setState({
                    errorMessage: 'Password should be greater than 8 characters.'
                })
                return
            }
            if (this.state.buttonEnable) {
                this.setState({
                    buttonEnable: false
                })


                axios
                    .post('/register', this.state.credential)
                    .then((response) => {
                        toast(response.data.message, { type: 'success' });
                        setTimeout(() => { history.push('/'); window.location.reload() }, 2000)

                    }).catch((error) => {
                        this.setState({ errorMessage: error.data.message })
                    })
                this.setState({
                    buttonEnable: true
                })
            }
        } catch (error) { }
    }

    render() {
        return (
            <div>
                <Navigation />

                {/* <!-- Register Section Begin --> */}
                <div className="register-login-section spad">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 offset-lg-3">
                                <div className="register-form">
                                    <h2>Register</h2>
                                    <form >
                                        <div className="group-input">
                                            <label for="fullName">Full Name *</label>
                                            <input type="text" onChange={this.onChangeHandler} value={this.state.credential.fullName} name="fullName" id="fullName" />
                                        </div>

                                        <div className="group-input">
                                            <label for="username">Email address *</label>
                                            <input type="email" onChange={this.onChangeHandler} name="email" value={this.state.credential.email} id="username" />
                                        </div>
                                        <div className="group-input">
                                            <label for="pass">Password *</label>
                                            <input type="password" onChange={this.onChangeHandler} id="pass" value={this.state.credential.password} name="password" />
                                        </div>
                                        <div className="group-input">
                                            <label for="con-pass">Confirm Password *</label>
                                            <input type="password" onChange={this.onChangeHandler} id="con-pass" value={this.state.credential.confirmPassword} name="confirmPassword" />
                                        </div>
                                        <div className="group-input">
                                            <label for="username">Street Address *</label>
                                            <input type="text" onChange={this.onChangeHandler} value={this.state.credential.shippingAddress} id="username" name="shippingAddress" />
                                        </div>
                                        <div className="group-input">
                                            <label for="username">City *</label>
                                            <input type="text" onChange={this.onChangeHandler} value={this.state.credential.city} id="city" name="city" />
                                        </div>
                                        <div className="group-input">
                                            <label for="username">Country *</label>
                                            <input type="text" onChange={this.onChangeHandler} value={this.state.credential.country} id="username" name="country" />
                                        </div>
                                        <div className="group-input">
                                            <label for="username">Phone Number *</label>
                                            <input type="Number" onChange={this.onChangeHandler} name="phoneNumber" value={this.state.credential.phoneNumber} id="username" />
                                        </div>

                                        <button type="button" onClick={this.register} className="site-btn register-btn">REGISTER</button>
                                    </form>
                                    <div style={{ color: 'red' }}>{this.state.errorMessage}</div>
                                    <div style={{ color: 'green' }}>{this.state.successMessage}</div>
                                    <div className="switch-login">
                                        <Link to="/login" className="or-login">Or Login</Link>
                                        <ToastContainer />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Register Form Section End --> */}

                <Webfooter />

            </div >

        )
    }
}
export default Index