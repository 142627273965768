import React, { Component } from 'react';
import Axios from '../middleware/axios';
import ProductRow from './ProductRow';
import Navigation from './Navigation';
import Webfooter from './Footer';
import { ToastContainer, toast } from 'react-toastify';
import InfiniteLoader from 'react-infinite-loader'
import history from '../middleware/history';
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';

class Index extends Component {

    constructor(props) {
        super(props)
    }
    state = {
        productSlider: [],
        buttonEnable: false,
        page: 1,
        type: '',
        size: 4,
        emptied: false
    }

    componentWillMount() {
        const getToken = localStorage.getItem("login")
        if (!getToken) {
            history.push("/")
            window.location.reload(false)

        }
    }
    removeFromWishList = (event, key) => {
        try {
            if (this.state.buttonEnable) {

                this.setState({ buttonEnable: false })
                Axios
                    .delete('/wishlist', { stockId: event })
                    .then((response) => {
                        const productSlider = this.state.productSlider
                        productSlider.splice(key, 1)
                        this.setState({ productSlider, buttonEnable: true })
                        toast(response.data.message, { type: 'success', position: 'top-center' })
                    }).catch((error) => {
                        toast(error.data.message, { type: 'error', position: 'top-center' })

                    })
            }
        } catch (error) {
            toast(error.message, { type: 'error', position: 'top-center' })

        }
    }
    componentDidMount() {
        this.getData()
    }


    getData = (value) => {
        if (!this.state.emptied) {


            let page = this.state.page
            if (value) {
                page = 1
            }
            let path = `/wishlist?size=${this.state.size}&page=${page}&type=${this.state.type}`

            Axios
                .get(path)
                .then((response) => {
                    if (response.data.data.length) {

                        let productSlider = this.state.productSlider
                        productSlider = productSlider.concat(response.data.data)
                        this.setState({ productSlider, buttonEnable: true, page: this.state.page + 1 })


                    } else {
                        this.setState({ emptied: true })
                    }
                })

        }
    }
    seacher = (value) => {
        try {
            this.setState(prevState => ({
                prevState,
                page: 1,
                productSlider: [],
                type: value,
                emptied: false
            }), () => {

                this.getData(true)
            })

        } catch (error) { }

    }

    render() {
        const { productSlider } = this.state;
        return (
            <div>
                {/* <!-- Header Section Begin --> */}
                <Navigation search={true} getData={this.seacher} />


                <link rel="stylesheet" type="text/css" href="https://cdn.jsdelivr.net/gh/kenwheeler/slick@1.8.1/slick/slick-theme.css" />
                <link rel="stylesheet" type="text/css" href="https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.css" />
                <div className="product-list container">
                    <div className=" row">
                        <h3>My wish list</h3>
                    </div>
                    {this.state.productSlider.length ?
                        <div className=" row">
                            {
                                productSlider.map((product, i) => (
                                    <ProductRow product={product} location={i} key={i} modifyWishList={this.removeFromWishList} />
                                ))
                            }
                        </div>
                        : <span />
                    }


                </div>

                {!this.state.productSlider.length ?
                    <div style={{ textAlign: 'center', fontWeight: 'bold' }}>No item in your wish list</div>
                    : <span />
                }

                < InfiniteLoader visitStyle={{ alignContent: 'center' }} loaderStyle={{ alignText: 'center', alignContent: 'center' }} onVisited={this.getData} />

                <br /><br /><br />
                <ToastContainer />
                <WhatsAppWidget companyName='Bridalwear' replyTimeText='Typically replies within a day' message={`Hi! How can I help you?`} phoneNumber="+923292060000" />

                {/* <!-- Footer Section Begin --> */}
                <Webfooter />
                {/* <!-- Footer Section End --> */}

            </div>
        )
    }
}
export default Index