import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../Header';
import Footer from '../Footer';
import Nav from '../nav';
import Axios from '../../../middleware/axios';
import Pagination from "react-js-pagination";
import history from '../../../helpers/history'

class List extends Component {
	constructor(props) {
		super(props)
	}
	state = {
		stocks: [],
		size: 10,
		page: 1,
		buttonEnable: true,
		type: '',
		total: 0,
		current: 0,
		first: 0,
		last: 0,
		next: 0
	}
	componentDidMount() {
		this.fetchStock()
	}
	componentWillMount() {
		const getToken = localStorage.getItem("login")
		if (!getToken) {
			history.push("/admin/login")
			window.location.reload(false)

		}
	}
	onChange = (event) => {
		try {
			this.setState({ type: event.target.value })
		} catch (error) { }
	}
	fetchStock = (event) => {
		try {
			if (event) {
				event.preventDefault()
			}
			if (this.state.buttonEnable) {
				this.setState({ buttonEnable: false })
				let url = `/stocks?pagination=${true}&size=${this.state.size}&page=${this.state.page}`;
				if (this.state.type) {
					url = `/stocks?pagination=${true}&size=${this.state.size}&page=${this.state.page}&type=${this.state.type}`;
				}

				Axios
					.get(url)
					.then((response) => {
						this.setState({
							stocks: response.data.data,
							first: response.data.pagination.first,
							last: response.data.pagination.last,
							next: response.data.pagination.next,
							current: response.data.pagination.current,
							total: response.data.pagination.total,
							buttonEnable: true

						})
					}).catch(error => {
						this.setState({ buttonEnable: true })
					})
			}
		} catch (error) {
			this.setState({ buttonEnable: true })
		}
	}
	paginate = (count) => {
		try {
			this.setState({ page: count }, () => {

				this.fetchStock()
			})
		} catch (error) { }
	}
	render() {
		return (
			<div>
				<Header />
				<section id="main">
					<div className="container">
						<div className="row">
							<Nav />
							<div className="col-md-9">
								<div className="panel panel-default">
									<div className="panel-heading main-color-bg">
										<h3 className="panel-title">Stocks</h3>
									</div>
									<div className="panel-body">
										<div className="row">
											<div className="col-md-12">
												<form onSubmit={this.fetchStock}>
													<input type="text" className="form-control" name='type' placeholder="Filter Stocks by Type" value={this.state.type} onChange={this.onChange} />
												</form>
												<div className="float-right" >
													<Link to={'/admin/stockadd/'} style={{ marginTop: "12px" }} className="btn btn-success">Add Stock</Link>
												</div>
											</div>
										</div>
										<br />

										<table className="table table-striped table-hover">
											<tbody>
												<tr>
													<th>Title</th>
													<th>Price</th>
													<th>Quantity</th>
													<th>Sold</th>
													<th>Type</th>
													<th>Created At</th>
													<th></th>
												</tr>
												{
													this.state.stocks.map((stock, i) => (
														<tr key={i}>
															<td>{stock.title}</td>
															<td>{stock.price}</td>
															<td>{stock.quantity}</td>
															<td>{stock.quantity ? "In Stock" : "Out of Stock"}</td>
															<td>{stock.type}</td>
															<td>{new Date(stock.createdAt).toDateString()}</td>
															<td> <Link to={`/stockview/${stock._id}`} className="btn btn-primary">View</Link></td>
														</tr>
													))
												}
											</tbody>
										</table>
									</div>
									<div style={{ textAlign: 'center' }}>
										<Pagination
											activePage={this.state.page}
											itemsCountPerPage={this.state.size}
											totalItemsCount={this.state.total}
											pageRangeDisplayed={5}
											onChange={this.paginate}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				<Footer />
			</div>
		)
	}
}
export default List