import React, { Component } from 'react';

class Header extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div>
        <footer id="footer"><p>Copyright 2022</p></footer>
      </div>
    )
  }
}
export default Header