import axios from 'axios';
import history from './history'
class Service {
  constructor() {
    let service = axios.create({

      headers: {
        authorization: `bearer ${localStorage.getItem('login')} `
      }
    });
    service.interceptors.response.use(this.handleSuccess, this.handleError);
    this.service = service;
  }

  handleSuccess(response) {
    return response;
  }

  handleError = (error) => {

    if (error.response.status == 401 || error.response.status == 403) {
      localStorage.removeItem('login');
      history.push('/login');
      window.location.reload()
    }
    return Promise.reject(error.response)
  }


  get(path) {
    return this.service.get(path).then(
      (response) => response
    );
  }

  patch(path, payload) {
    return this.service.request({
      method: 'PATCH',
      url: path,
      responseType: 'json',
      data: payload
    }).then((response) => response)
  }

  post(path, payload) {
    return this.service.request({
      method: 'POST',
      url: path,
      responseType: 'json',
      data: payload
    }).then((response) => response)
  }
  delete(path, payload) {
    return this.service.request({
      method: 'DELETE',
      url: path,
      responseType: 'json',
      data: payload
    }).then((response) => response)
  }
  put(path, payload) {
    return this.service.request({
      method: 'PUT',
      url: path,
      responseType: 'json',
      data: payload
    }).then((response) => response)
  }
}

export default new Service;