import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../Header';
import Footer from '../Footer';
import Nav from '../nav';
import Pagination from "react-js-pagination";
import Axios from '../../../middleware/axios';

class List extends Component {
	constructor(props) {
		super(props)
	}
	state = {
		users: [],
		size: 10,
		page: 1,
		email: '',
		total: 0,
		current: 0,
		first: 0,
		last: 0,
		next: 0
	}
	componentDidMount() {
		this.fetchUsers()
	}
	paginate = (count) => {
		try {
			this.setState({ page: count }, () => {

				this.fetchUsers()
			})
		} catch (error) { }
	}
	fetchUsers = (event) => {
		try {
			if (event) {
				event.preventDefault()
			}
			let url = `/users?pagination=${true}&size=${this.state.size}&page=${this.state.page}`;
			if (this.state.email) {
				url = `/users?pagination=${true}&size=${this.state.size}&page=${this.state.page}&email=${this.state.email}`;
			}
			Axios
				.get(url)
				.then((response) => {
					this.setState({
						users: response.data.data,
						first: response.data.pagination.first,
						last: response.data.pagination.last,
						next: response.data.pagination.next,
						current: response.data.pagination.current,
						total: response.data.pagination.total

					})
				})
		} catch (error) { }
	}


	render() {
		return (
			<div>
				<Header />
				<section id="main">
					<div className="container">
						<div className="row">
							<Nav />
							<div className="col-md-9">
								<div className="panel panel-default">
									<div className="panel-heading main-color-bg">
										<h3 className="panel-title">Users</h3>
									</div>
									<div className="panel-body">
										<div className="row">
											<div className="col-md-12">
												<form onSubmit={this.updateAPIData}>
													<input type="text" className="form-control" value={this.state.email} name="email" placeholder="Filter Users by email" onChange={this.onChange} />
												</form>
											</div>
										</div>
										<br />

										<table className="table table-striped table-hover">
											<tbody>
												<tr>
													<th>Name</th>
													<th>Email</th>
													<th>Phone Number</th>
													<th>Shipping Address</th>
													<th>Action</th>
												</tr>
												{
													this.state.users.map((user, i) => (
														<tr key={i}>
															<td>{user.fullName}</td>
															<td key={user.email}>{user.email}</td>
															<td key={user.phoneNumber}>{user.phoneNumber}</td>
															<td key={user.shippingAddress}>{user.shippingAddress}</td>
															<td><Link className="btn btn-default" to={`/userview/${user._id}`}>Edit</Link> <Link to={`/userview/${user._id}`} className="btn btn-primary">View</Link></td>
														</tr>
													))
												}
											</tbody>
										</table>
										<div style={{ textAlign: 'center' }}>
											<Pagination
												activePage={this.state.page}
												itemsCountPerPage={this.state.size}
												totalItemsCount={this.state.total}
												pageRangeDisplayed={5}
												onChange={this.paginate}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<Footer />
			</div>
		)
	}
}
export default List