import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import variable from '../helpers/variable';

export const ProductDetails = ({ product }) => {
    const [currentIndex, setCurrentIndex] = useState();
    const [list, setList] = useState(null);
    const handleChange = (index) => {
        setCurrentIndex(index);
    }
    useEffect(() => {
        if (Object.keys(product).length) {
            setList(product)
        }
    }, [product])
    return (
        <div className="grid-productDetails">
            {list && (
                <span>
                    {list.pictures.length &&
                        <Carousel
                            showThumbs={true}
                            showArrows={false}
                            autoPlay={false}
                            infiniteLoop={false}
                            selectedItem={list.pictures[currentIndex]}
                            onChange={handleChange}
                        >
                            {list.pictures.map((image) => {
                                return (
                                    <div className="product-item-images" key={image._id}>
                                        <img src={`${variable.url}${image.pictureUrl}`} alt={image._id} />
                                    </div>
                                )
                            })}
                        </Carousel>
                    }
                </span>
            )}
        </div >
    );
}

