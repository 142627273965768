import "react-multi-carousel/lib/styles.css";
import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import variable from '../helpers/variable';
import { Link } from "react-router-dom";

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

export const HorizontelScroller = ({ productSlider }) => {
    const [slides, setSlides] = useState([]);
    useEffect(() => {
        if (productSlider) {
            const copy = [...slides]
            for (const key in productSlider) {
                if (productSlider[key].displayOnSlider) {
                    copy.push(productSlider[key])
                }
            }
            setSlides(copy)
        }
    }, [productSlider])
    return (
        <div className='container' >
            {slides && slides.length > 0 && (
                <Carousel
                    responsive={responsive}
                    infinite={true}
                    autoPlay={true}
                    autoPlaySpeed={5000}
                    customTransition="all .5"
                    transitionDuration={500}
                >
                    {slides.length && slides.map((value) => {
                        return (
                            <div key={value._id} className="carosal-item">
                                <div style={{
                                    position: "fixed",
                                    top: "7%",
                                    left: 0,
                                    backgroundColor: "#e7ab3c",
                                    padding: "5px 10px",
                                    textTransform: "uppercase",
                                    color: "#ffffff"
                                }}>
                                    20% OFF
                                </div>
                                <img style={{ height: "inherit" }} src={`${variable.url}${value.pictures[0].pictureUrl}`} alt={value._id} />
                                <button className="slider-btn">
                                    <Link to={"/product/" + value._id}>+ Quick View</Link>
                                </button>
                            </div>
                        )
                    })}
                </Carousel>
            )}
        </div>
    )
}