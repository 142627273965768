import React from 'react';
import { useState } from 'react';
import Axios from '../../../middleware/axios';

const AddStock = () => {

    const [quantity, setQuantity] = useState(0);
    const [selectedFile, setSelectedFile] = useState([]);
    const [title, setTitle] = useState('');
    const [price, setPrice] = useState('');
    const [facebookUrl, setFacebookUrl] = useState('');
    const [etag, setEtag] = useState('');
    const [brand, setBrand] = useState('');
    const [type, setType] = useState('');
    const [displayOnSlider, setDisplayOnSlider] = useState(false);
    const [buttonEnable, setButtonEnable] = useState(true);

    const [pictures, setPictures] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');

    const onFileChange = (e) => {
        const copy = [...selectedFile]
        copy.push(e.target.files[0])
        setSelectedFile(copy);
    };
    const deleteFile = (fileName) => {
        const copy = [...selectedFile]
        const filter = copy.filter((e) => e.name !== fileName);
        setSelectedFile(filter);
    }

    const handleChange = (e) => {
        if (e.target.name == 'title') {
            setTitle(e.target.value)
        } else if (e.target.name == 'price') {
            setPrice(e.target.value)
        }
        else if (e.target.name == 'quantity') {
            setQuantity(e.target.value)
        }
        else if (e.target.name == "facebookUrl") {
            setFacebookUrl(e.target.value)
        }
        else if (e.target.name == 'brand') {
            setBrand(e.target.value)
        }
        else if (e.target.name == 'type') {
            setType(e.target.value)
        }
        else if (e.target.name == 'etag') {
            setEtag(e.target.value)
        }
        else if (e.target.name == 'displaySlider') {
            setDisplayOnSlider(e.target.value)
        }

    };

    const addStock = () => {
        try {
            if (buttonEnable && pictures.length) {
                setButtonEnable(false)
                const Data = {
                    title: title,
                    price: price,
                    brand: brand,
                    type: type,
                    facebookUrl: facebookUrl,
                    quantity: quantity,
                    etag: etag,
                    displayOnSlider,
                    pictures: pictures
                };

                Axios.post(`/stocks/`, Data)
                    .then((response) => {
                        setErrorMessage(response.data.message);

                    }).catch((error) => {
                        setErrorMessage(error.data.message);
                    });
                setButtonEnable(false)

            }
        } catch (error) {
            setErrorMessage(error.data.message);
            setButtonEnable(false)

        }

    }

    const onFileUpload = () => {
        try {
            if (buttonEnable) {

                setButtonEnable(false)
                let array = [];
                for (let key in selectedFile) {
                    const formData = new FormData()
                    formData.append('type', 'collections')
                    formData.append('file', selectedFile[key])

                    array.push(Axios.post(`/file`, formData))
                }
                Promise.all(array)
                    .then((response) => {
                        const copy = [...pictures]
                        for (let key in response) {
                            copy.push({
                                pictureUrl: response[key].data.data.filePath,
                            })
                        }
                        setPictures(copy)
                        setErrorMessage("All files are uploaded..!");
                    }).catch((error) => {
                        setErrorMessage(error.data.message);
                    });
            }
            setButtonEnable(true)

        } catch (error) {
            setErrorMessage(error.message);
            setButtonEnable(true)

        }
    };


    return (
        <div className="container orderdetailContainer">
            <article className="card">
                <header className="card-header"> Add Stock </header>
                <div className="card-body">
                    <div className="form-group">
                        <label >Title</label>
                        <input type="text" onChange={(e) => handleChange(e)} name='title' className="form-control" />
                    </div>
                    <div className="form-group">
                        <label >Facebook Url</label>
                        <input type="text" onChange={(e) => handleChange(e)} name='facebookUrl' className="form-control" />
                    </div>
                    <div className="form-group">
                        <label >Display on slides</label>
                        <select type="select" onChange={(e) => handleChange(e)} name='displaySlider' className="form-control" >
                            <option value="">Select Visablity</option>
                            <option value={true}>Yes</option>
                            <option value={false}>No</option>
                        </select>
                    </div>

                    <div className="form-group">
                        <label >Brand</label>
                        <input type="text" onChange={(e) => handleChange(e)} name='brand' className="form-control" />
                    </div>
                    <div className="form-group">
                        <label >Type</label>
                        <input type="text" onChange={(e) => handleChange(e)} name='type' className="form-control" />
                    </div>
                    <div className="form-group">
                        <label >Etag</label>
                        <select type="select" onChange={(e) => handleChange(e)} name='etag' className="form-control" >
                            <option value="">Select Etag</option>
                            <option value="kashish">Kashish beauty products</option>
                            <option value="on-demand">On demand</option>
                            <option value="summer-collection">Summer collection</option>
                            <option value="lawn-unstich">Lawn unstitch</option>
                            <option value="sale">Sale</option>
                            <option value="lawn-sale">Lawn sale</option>
                            <option value="luxury-sale">Luxury sale</option>
                            <option value="winter-sale">Winter sale</option>
                            <option value="new-arrival">New arrival</option>
                            <option value="luxury-collection">Luxury collection</option>
                            <option value="saries">Saries</option>
                            <option value="lehnga-dresses">Lehnga dresses</option>
                            <option value="luxury-party-wear">Luxury party wear</option>
                            <option value="original">Orginal</option>
                            <option value="winter-collection">Winter collection</option>
                            <option value="velvet">Velvet</option>
                            <option value="cotton">Cotton</option>
                            <option value="marina">Marina</option>
                            <option value="linen">Linen</option>
                            <option value="ready-to-wear">Ready to wear</option>

                        </select>
                    </div>
                    <div className="form-group">
                        <label >Price</label>
                        <input type="text" onChange={(e) => handleChange(e)} name='price' className="form-control" />
                    </div>
                    <div className="form-group">
                        <label >Quantity</label>
                        <input type="text" onChange={(e) => handleChange(e)} name='quantity' className="form-control" />
                    </div>
                    <hr />
                    {selectedFile && (
                        <ul className="list-group">
                            {selectedFile.map((v, i) => {
                                return (
                                    <li key={i} className="list-group-item d-flex justify-content-between align-items-center">
                                        {v.name}
                                        <span className="badge badge-danger">
                                            <button onClick={() => deleteFile(v.name)} className="close" aria-label="Close">
                                                <span style={{ color: "#ffffff" }} aria-hidden="true">&times;</span>
                                            </button>
                                        </span>
                                    </li>
                                )
                            })}
                        </ul>
                    )}
                    <div className="form-group">
                        <label for="upload">
                            <span style={{ fontSize: "1.8em", cursor: "pointer" }} className="glyphicon glyphicon-folder-open" aria-hidden="true"></span>
                            <input onChange={(e) => onFileChange(e)} multiple={true} type="file" id="upload" style={{ display: 'none' }} />
                        </label>
                        <br />
                    </div>
                    <div className="form-group">
                        <span className='btn btn-primary' onClick={() => onFileUpload()}>Upload!</span>
                    </div>

                    <span className='customMessage'>{errorMessage}</span>

                    <hr />
                    <button type='button' onClick={() => addStock()} className='btn btn-primary'>Submit</button>
                </div>
            </article>
        </div>
    )

}

export default AddStock