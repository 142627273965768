
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Login from '../login.js'
import Home from '../Home.js';
import Register from '../register';
import Shoppingcart from '../Shoppingcart';
import Product from '../ProductDetail';
import ResetPassword from '../reset-password'
import Checkout from '../checkout'
import SetPassword from '../set-password'
import Dashboard from '../admin/dashboard';
import Adminlogin from '../admin/login';
import Adminuser from '../admin/users/list';
import Adminuserlist from '../admin/users/read';
import Adminstock from '../admin/stocks/list';
import Adminorder from '../admin/orders/list';
import Adminorderlist from '../admin/orders/edit';
import Adminstockslist from '../admin/stocks/edit';
import Adminstocksadd from '../admin/stocks/create';
import DeliveryChargesAdd from '../admin/delivery-charges/add';
import DeliveryChargesList from '../admin/delivery-charges/list'

import Profile from '../Profile'
import Wishlist from '../Wishlist'



const Routes = () => {
    return (
        <Router >
            <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/product/:id" component={Product} />
                <Route exact path="/set-password/:id" component={SetPassword} />
                <Route exact path="/register" component={Register} />
                <Route exact path="/shoppingcart" component={Shoppingcart} />
                <Route exact path="/wish-list" component={Wishlist} />
                <Route exact path="/reset" component={ResetPassword} />
                <Route exact path="/checkout" component={Checkout} />
                <Route exact path="/admin/settings" component={Adminstock} />
                <Route exact path="/me" component={Profile} />
                <Route exact path="/admin/dashboard" component={Dashboard} />
                <Route exact path="/admin/login" component={Adminlogin} />
                <Route exact path="/admin/users" component={Adminuser} />
                <Route exact path="/admin/stocks" component={Adminstock} />
                <Route exact path="/admin/orders" component={Adminorder} />
                <Route exact path="/admin/delivery-charges-list" component={DeliveryChargesList} />
                <Route exact path="/userview/:id" component={Adminuserlist} />
                <Route exact path="/orderview/:id" component={Adminorderlist} />
                <Route exact path="/stockview/:id" component={Adminstockslist} />
                <Route exact path="/admin/delivery-charges/:id" component={Adminstock} />
                <Route exact path="/admin/stockadd/" component={Adminstocksadd} />
                <Route exact path="/admin/delivery-charges-add" component={DeliveryChargesAdd} />
                <Route exact path="/:id" component={Home} />
            </Switch>
        </Router>
    )
}
export default Routes;
