import React from 'react';
import { useState, useEffect } from 'react';
import Axios from '../../../middleware/axios';
import Header from '../Header';
const AddCharges = () => {

    const [country, setCountry] = useState(0);
    const [countryList, setCountryList] = useState([])
    const [otherKgs, setOtherKgs] = useState('');
    const [firstKg, setFirstKg] = useState('');
    const [buttonEnable, setButtonEnable] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');



    const handleChange = (e) => {
        if (e.target.name == 'country') {
            setCountry(e.target.value)
        }
        else if (e.target.name == "firstKg") {
            setFirstKg(e.target.value)
        }
        else if (e.target.name == 'otherKgs') {
            setOtherKgs(e.target.value)
        }
    };


    const addStock = () => {
        try {
            if (buttonEnable) {
                setButtonEnable(false)
                const Data = {
                    country: country,
                    etag: etag,
                    displayOnSlider,
                    pictures: pictures
                };

                Axios.post(`/delivery-charges/`, Data)
                    .then((response) => {
                        setErrorMessage(response.data.message);

                    }).catch((error) => {
                        setErrorMessage(error.data.message);
                    });
                setButtonEnable(false)

            }
        } catch (error) {
            setErrorMessage(error.data.message);
            setButtonEnable(false)

        }

    }


    useEffect(() => {
        Axios.get(`/country-list`)
            .then((response) => {
                if (response.data.data)
                    setCountryList(response.data.data);
            });
    })

    return (
        <div>
            <Header />

            <div className="container orderdetailContainer">
                <article className="card">
                    <header className="card-header"> Add Delivery Charges </header>
                    <div className="card-body">

                        <div className="form-group">
                            <label >Country Name</label>
                            <select type="select" onChange={(e) => handleChange(e)} name='country' className="form-control" value={country} >
                                <option value="">Select Country</option>
                                {countryList.map((el, i) => {
                                    <option value={el}>{el}</option>
                                })}
                            </select>
                            <div className="form-group">
                                <label >First KG</label>
                                <input type="text" onChange={(e) => handleChange(e)} name='firstKg' className="form-control" value={firstKg} />
                            </div>
                        </div>
                        <div className="form-group">
                            <label >Other KGs</label>
                            <input type="text" onChange={(e) => handleChange(e)} name='otherKgs' className="form-control" value={otherKgs} />
                        </div>
                        <hr />
                        <span className='customMessage'>{errorMessage}</span>

                        <hr />
                        <button type='button' onClick={() => addStock()} className='btn btn-primary'>Submit</button>
                    </div>
                </article>
            </div>
        </div>)

}

export default AddCharges