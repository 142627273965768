import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../Header';
import Footer from '../Footer';
import Nav from '../nav';
import Axios from '../../../middleware/axios';
import Pagination from "react-js-pagination";

class List extends Component {
	constructor(props) {
		super(props)
	}
	state = {
		orders: [],
		status: '',
		total: 0,
		size: 10,
		current: 0,
		page: 1,
		first: 0,
		last: 0,
		next: 0

	}
	componentDidMount() {
		this.fetchOrders()
	}
	paginate = (count) => {
		try {
			this.setState({ page: count }, () => {

				this.fetchOrders()
			})
		} catch (error) { }
	}

	fetchOrders = (event) => {
		try {
			if (event) {
				event.preventDefault()
			}
			this.setState({ orders: [] })
			let url = `/orders?pagination=${true}&size=${this.state.size}&page=${this.state.page}`;
			if (this.state.status) {
				url = `/orders?pagination=${true}&size=${this.state.size}&page=${this.state.page}&status=${this.state.status}`;

			}

			Axios
				.get(url)
				.then((response) => {
					this.setState({
						orders: response.data.data,
						first: response.data.pagination.first,
						last: response.data.pagination.last,
						next: response.data.pagination.next,
						current: response.data.pagination.current,
						total: response.data.pagination.total
					})
				})
		} catch (error) {

		}
	}
	onChange = (event) => {
		try {
			event.preventDefault()
			this.setState({
				status: event.target.value
			})
		} catch (error) { }
	}
	render() {
		return (
			<div>
				<Header />
				<section id="main">
					<div className="container">
						<div className="row">
							<Nav />
							<div className="col-md-9">
								<div className="panel panel-default">
									<div className="panel-heading main-color-bg">
										<h3 className="panel-title">Orders</h3>
									</div>
									<div className="panel-body">
										<div className="row">
											<div className="col-md-12">
												<form onSubmit={this.fetchOrders}>
													<input type="text" onChange={this.onChange} value={this.state.status} className="form-control" placeholder="Filter Orders by Status" />
												</form>
											</div>
										</div>
										<br />

										<table className="table table-striped table-hover">
											<tbody>
												<tr>
													<th>Order Id</th>
													<th>Email</th>
													<th>Total Price</th>
													<th>Status</th>
													<th>Delivery Address</th>
													<th>Order Date</th>
													<th>Phone Number</th>
													<th>Action</th>
												</tr>
												{
													this.state.orders.map((order, i) => (
														<tr key={i}>

															<td>{i + 1}</td>
															<td>{order.email ? order.email : 'Not Provided'}</td>
															<td>{order.totalPrice}</td>
															<td>{order.status}</td>
															<td>{order.deliveryAddress}</td>
															<td>{new Date(order.createdAt).toDateString()}</td>
															<td>{order.phoneNumber}</td>
															<td> <Link to={`/orderview/${order._id}`} className="btn btn-primary">View</Link></td>
														</tr>
													))
												}
											</tbody>
										</table>
										<div style={{ textAlign: 'center' }}>
											<Pagination
												activePage={this.state.page}
												itemsCountPerPage={this.state.size}
												totalItemsCount={this.state.total}
												pageRangeDisplayed={5}
												onChange={this.paginate}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				<Footer />
			</div>
		)
	}
}
export default List