import React, { Component } from 'react'
import '../../public/css/bootstrap.min.css';
import '../../public/css/elegant-icons.css';
import '../../public/css/font-awesome.min.css'
import '../../public/css/jquery-ui.min.css';
import '../../public/css/nice-select.css';
import '../../public/css/owl.carousel.min.css';
import '../../public/css/slicknav.min.css';
import '../../public/css/style.css';
import '../../public/css/themify-icons.css'
import Navigation from './Navigation';
import axios from '../middleware/axios';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import history from '../middleware/history';

class Index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            credential: {
                email: '',
            },
            buttonEnable: true,
            errorMessage: '',
            successMessage: ''
        }
    }
    onChangeHandler = (event) => {
        this.setState({
            errorMessage: ''
        })
        const credential = this.state.credential
        credential[event.target.name] = event.target.value
        this.setState({
            credential
        })
    }
    resetPassword = (event) => {
        try {
            event.preventDefault()
            if (!this.state.credential.email.trim()) {
                this.setState({
                    errorMessage: 'Email address is required'
                })
                return
            }
            if (this.state.buttonEnable) {
                this.setState({
                    buttonEnable: false
                })
                axios
                    .post('/reset-password', this.state.credential)
                    .then((response) => {
                        toast(response.data.message, { type: 'success' });
                        setTimeout(() => {
                            history.push('/login')
                            window.location.reload()
                        }, 2000)
                    }).catch((error) => {
                        this.setState({ errorMessage: error.data.message })
                    })
                this.setState({
                    buttonEnable: true
                })
            }
        } catch (error) {
        }
    }

    render() {
        return (
            <div>
                <Navigation />

                <div className="register-login-section spad">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 offset-lg-3">
                                <div className="login-form">
                                    <h2>Reset Password</h2>
                                    <form >
                                        <div className="group-input">
                                            <label for="username">Email address *</label>
                                            <input type="email" name='email' value={this.state.credential.email} onChange={this.onChangeHandler} id="username" />
                                        </div>
                                        <div style={{ color: 'red' }}>{this.state.errorMessage}</div>
                                        <div className="group-input gi-check">
                                            <div className="gi-more">
                                                <Link to="/login" className="forget-pass">Login</Link>
                                            </div>
                                        </div>
                                        <button type="button" onClick={this.resetPassword} className="site-btn login-btn">Reset</button>
                                    </form>
                                    <ToastContainer />
                                    <div className="switch-login">
                                        <Link to="/register" className="or-login">Or Create An Account</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        )
    }
}
export default Index