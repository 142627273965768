import React, { Component } from 'react'
import '../../public/css/bootstrap.min.css';
import '../../public/css/elegant-icons.css';
import '../../public/css/font-awesome.min.css'
import '../../public/css/jquery-ui.min.css';
import '../../public/css/nice-select.css';
import '../../public/css/owl.carousel.min.css';
import '../../public/css/slicknav.min.css';
import '../../public/css/style.css';
import '../../public/css/themify-icons.css'
import Navigation from './Navigation';
import axios from '../middleware/axios';
import { Link } from 'react-router-dom'
import Webfooter from './Footer';
import history from '../middleware/history';
class Index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            credential: {
                email: '',
                password: '',
                type: 'Customer'
            },
            errorMessage: '',
            successMessage: ''
        }
    }
    componentWillMount() {
        const getToken = localStorage.getItem("login")
        if (getToken) {
            history.push("/wish-list")
            window.location.reload(false)

        }
    }
    onChangeHandler = (event) => {
        try {
            this.setState({
                errorMessage: ''
            })
            const credential = this.state.credential
            credential[event.target.name] = event.target.value
            this.setState({
                credential
            })
        } catch (error) { }
    }
    signIn = (event) => {
        try {
            event.preventDefault()
            if (!this.state.credential.email.trim() || !this.state.credential.password.trim()) {
                this.setState({
                    errorMessage: 'Email address and password fields are required'
                })
                return
            }
            axios
                .post('/login', this.state.credential)
                .then((response) => {
                    if (response.data.data.accessToken) {

                        localStorage.setItem('login', response.data.data.accessToken)
                        history.push('/wish-list')
                        window.location.reload(false)
                    }
                }).catch((error) => {
                    this.setState({ errorMessage: error.data.message })
                })
        } catch (error) { }
    }
    render() {
        return (
            <div>
                <Navigation />

                <div className="register-login-section spad">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 offset-lg-3">
                                <div className="login-form">
                                    <h2>Login</h2>
                                    <form >
                                        <div className="group-input">
                                            <label for="username">Email address *</label>
                                            <input type="email" name='email' value={this.state.credential.email} onChange={this.onChangeHandler} id="username" />
                                        </div>
                                        <div className="group-input">
                                            <label for="pass">Password *</label>
                                            <input type="password" name='password' value={this.state.credential.password} onChange={this.onChangeHandler} id="pass" />
                                        </div>
                                        <div style={{ color: 'red' }}>{this.state.errorMessage}</div>
                                        <div className="group-input gi-check">
                                            <div className="gi-more">
                                                <Link to="/reset" className="forget-pass">Forget your Password</Link>
                                            </div>
                                        </div>
                                        <button type="button" onClick={this.signIn} className="site-btn login-btn">Sign In</button>
                                    </form>
                                    <div className="switch-login">
                                        <Link to="/register" className="or-login">Or Create An Account</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Webfooter />
            </div>

        )
    }
}
export default Index