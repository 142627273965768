import React, { Component } from 'react'
import Navigation from './Navigation';
import Axios from '../middleware/axios'
import { Link } from 'react-router-dom';
import ReactPlayer from "react-player/lazy";
import { ToastContainer, toast } from 'react-toastify';
import Webfooter from '../components/Footer'
import ProductRow from './ProductRow';

import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
import { FacebookEmbed } from 'react-social-media-embed'
import './style.css'
import 'react-toastify/dist/ReactToastify.css';
import { ProductDetails } from './banner';

class ProductDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            product: {},
            productApiCode: [],
            errorMessage: '',
            added: false,
            buyItems: [],
            selectedSize:0,
            relevantData: null,
            totalItems: 0,
            subTotal: 0,
            quantityToBuy: 1,
            imageUrl: '/img/no-image.jpg',
            refreshCart: false,
            loading: true,
            videoLoad: false,
            buttonEnable: false,
            myParams: null
        }
    }
    componentDidMount() {

        try {
            this.setState({ videoLoad: true, myParams: this.props.match.params.id })
            this.getProduct()
        } catch (error) { }
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.myParams !== this.props.match.params.id) {
            this.getProduct()
        }
    }

    getProduct = () => {
        Axios
            .get(`/stocks/${this.props.match.params.id}`)
            .then((response) => {
                this.setState({
                    product: response.data.data,
                    productApiCode: response.data.message,
                    buttonEnable: true
                })

                let imgUrl = this.state.product.pictures[0].pictureUrl;
                if (imgUrl != undefined) {
                    this.setState({ imageUrl: imgUrl, loading: false })
                    this.getCartData()
                    this.setState({ videoLoad: false })
                }
                if (this.state.product.type) {
                    Axios.get(`/stocks?size=6&page=4&type=${this.state.product.type}&etag=`)
                        .then((response) => {
                            if (response.data.data.length) {
                                this.setState({ relevantData: response.data.data })
                            }
                        })

                }
            })
    }

    onQuantityChange = (event) => {
        this.setState({
            quantityToBuy: event.target.value
        })
    }

    getCartData = () => {
        try {
            let getFromLocalStroage = localStorage.getItem('orders')
            let getFromLocalStroageSubTotal = localStorage.getItem('subTotal')
            this.setState({ buyItems: JSON.parse(getFromLocalStroage) })
            this.setState({ subTotal: JSON.parse(getFromLocalStroageSubTotal) })

            if (getFromLocalStroage) {
                getFromLocalStroage = JSON.parse(getFromLocalStroage)
                this.setState({ totalItems: getFromLocalStroage.length })
            }
        } catch (error) { }
    }

    addtocartProduct = (event) => {
        try {
            event.preventDefault()
            if (this.state.added) {
                toast('Product already added to cart', { type: 'success' });

                return
            }

            if (this.state.quantityToBuy < 1) {
                this.setState({ errorMessage: 'Quantity must be greater than 0' })
                return
            }

            let getFromLocalStroage = localStorage.getItem('orders')
            let getSubTotalLocalStroage = localStorage.getItem('subTotal')

            if (getFromLocalStroage) {
                getFromLocalStroage = JSON.parse(getFromLocalStroage);
                getSubTotalLocalStroage = JSON.parse(getSubTotalLocalStroage);

            } else {
                getFromLocalStroage = [];
                getSubTotalLocalStroage = 0;
            }

            const data = {
                "title": this.state.product.title,
                "price": this.state.product.price,
                "imageUrl": this.state.imageUrl,
                "brand": this.state.product.brand,
                "size":this.state.selectedSize,
                "quantity": this.state.quantityToBuy,
                "_id": this.state.product._id
            }

            const currentSubTotal = this.state.product.price * this.state.quantityToBuy;
            const subTotal = getSubTotalLocalStroage + currentSubTotal
            getFromLocalStroage.push(data)

            const item = JSON.stringify(getFromLocalStroage)

            localStorage.setItem('orders', item)
            localStorage.setItem('subTotal', JSON.stringify(subTotal))
            this.setState({ cartItem: item, subTotal: subTotal, totalItems: this.state.totalItems + 1, added: true })

        } catch (error) { }
    }
    selectSize = (event)=>{
    this.setState({
      selectedSize: event.target.value
    })
    }
    addToWishList = (event) => {
        try {
            event.preventDefault()
            if (this.state.buttonEnable) {
                this.setState({
                    buttonEnable: false
                })
                Axios
                    .post('/wishlist', { stockId: this.props.match.params.id })
                    .then((response) => {
                        toast(response.data.message, { type: 'success', position: 'top-center' })
                        this.setState({ buttonEnable: true })

                    }).catch((error) => {
                        toast(error.data.message, { type: 'error', position: 'top-center' })

                    })
            }
        } catch (error) {
            toast(error.message, { type: 'error', position: 'top-center' })

        }
    }
    render() {
        return (
            <div>
                <Navigation buyItems={this.state.buyItems} subTotal={this.state.subTotal} totalItems={this.state.totalItems} />

                <div className='pd-details'>
                    <div className='item-details'>
                        <ProductDetails product={this.state.product} />
                    </div>

                    <div className="item-content">
                        <div className='box' style={{
                            paddingTop: " 3%"
                        }}>
                            <div className='box1'>
                                <span className='title'>{this.state.product.title}</span>
                            </div>
                            <div className='box2'>
                                <Link to="#" className="heart-icon" onClick={this.addToWishList} ><i className="icon_heart_alt"></i></Link>
                            </div>
                        </div>
                        <div className='box'>
                            <div className='box1'>
                                <span className='pkr'>PKR {this.state.product.price}</span>
                            </div>
                            <div className='box2'></div>
                        </div>
                        <div className='box'>
                            <div className='box11-left'>
                                <div className='box11-01'>
                                    <button onClick={() => {
                                        if (this.state.quantityToBuy > 1) {
                                            this.setState({ quantityToBuy: this.state.quantityToBuy - 1 })
                                        }
                                    }}><i className="glyphicon glyphicon-minus"></i></button>
                                </div>
                                <div className='box11-02'>
                                    <span>{this.state.quantityToBuy}</span>
                                </div>
                                <div className='box11-03'>
                                    <button onClick={() => {
                                        if (this.state.quantityToBuy < 5) {
                                            this.setState({ quantityToBuy: this.state.quantityToBuy + 1 })
                                        }
                                    }}><i className="glyphicon glyphicon-plus"></i></button>
                                </div>
                            </div>
                            <div className='box11-right'>
                                <button onClick={this.addtocartProduct} >Add To Cart</button>
                            </div>
                        </div>
                        <div className='box-ul'>
                            <ul className="pd-tags">
                                <li ><span className='li-heading'>CATEGORIES</span>: <span className='li-txt'>{this.state.product.type}</span></li>
                                <li ><span className='li-heading'>STATUS</span>: <span className='li-txt'>{this.state.product.quantity ? 'In stock' : 'Out of stock'}</span></li>
                                {this.state.product.etags=='shoes'?          
                                <li>
                                    <span className='li-heading'>Size</span>: <span className='li-txt'>
                                        <input type='radio'onChange={this.selectSize} value={36} checked={this.state.selectedSize == 36}   />36&nbsp;&nbsp;
                                        <input type='radio'onChange={this.selectSize} value={37} checked={this.state.selectedSize == 37}  />37&nbsp;&nbsp;
                                        <input type='radio'onChange={this.selectSize} value={38}checked={this.state.selectedSize == 38} />38 &nbsp;&nbsp;
                                        <input type='radio'onChange={this.selectSize} value={39} checked={this.state.selectedSize == 39} />39&nbsp;&nbsp;
                                        <input type='radio'onChange={this.selectSize} value={40} checked={this.state.selectedSize == 40} />40&nbsp;&nbsp;
                                        <input type='radio'onChange={this.selectSize} value={41} checked={this.state.selectedSize == 41}/>41&nbsp;&nbsp;
                                        <input type='radio'onChange={this.selectSize} value={42} checked={this.state.selectedSize == 42}/>42&nbsp;
                                          </span></li> 
                                :
                                <span/>
                                }
                            </ul>
                        </div>
                        <div className='box-error-message'>
                            <div style={{ color: 'red', fontWeight: 'bold' }}>{this.state.errorMessage}</div>
                        </div>
                    </div>
                </div>
                <div className='pd-details'>
                    <h3>Description</h3>
                </div>
                <div className='pd-detail-extra'>
                    <div className='video-box'>
                        {this.state.videoLoad &&
                            <img className='sm-loader' src='/icons/shipping-cart.gif' style={{ width: 100, height: 100 }} />
                        }

                        <div style={{ display: 'flex', justifyContent: 'center', width: 'auto', height: 'auto' }}>
                            <FacebookEmbed url={this.state.product.facebookUrl} />
                        </div>


                    </div>
                </div>
                {
                    this.state.relevantData && this.state.relevantData.length ?
                        <div className='pd-details'>
                            <h3>Related products</h3>
                        </div>
                        : <span />
                }
                <div className="grid-container">
                    {
                        this.state.relevantData && this.state.relevantData.length ?
                            this.state.relevantData.map((product, i) => (
                                <ProductRow product={product} key={i} modifyWishList={this.addToWishList} />
                            ))
                            : <span />
                    }
                </div>


                <ToastContainer />
                <WhatsAppWidget companyName='Bridalwear' replyTimeText='Typically replies within a day' message={`Hi! How can I help you?`} phoneNumber="+923292060000" />

                <Webfooter />
            </div >

        );
    }
}

export default ProductDetail;
