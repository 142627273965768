import React, { Component } from 'react'
import axios from '../middleware/axios';
import Navigation from './Navigation';
import { ToastContainer, toast } from 'react-toastify';
import history from '../middleware/history';

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            credential: {
                email: '',
                shippingAddress: '',
                phoneNumber: '',
                fullName: '',
                city: '',
                country: '',
                password: '',
                newPassword: '',
                confirmPassword: ''
            },
            userId: '',
            buttonEnable: false,
            errorMessage: '',
            successMessage: '',
        }
    }

    componentDidMount() {

        axios.get('/profile').then((response) => {
            if (response.data.data) {
                const credential = response.data.data
                this.setState({ credential, userId: response.data.data._id, buttonEnable: true })
            }

        })
    }

    componentWillMount() {
        const getToken = localStorage.getItem("login")
        if (!getToken) {
            history.push("/")
            window.location.reload(false)

        }
    }

    onChangeHandler = (event) => {
        try {

            this.setState({
                errorMessage: '',
                successMessage: ''
            })
            const credential = this.state.credential
            credential[event.target.name] = event.target.value
            this.setState({
                credential
            })
            if (event.target.name == 'confirmPassword') {
                if (credential.newPassword != credential.confirmPassword) {
                    this.setState({
                        errorMessage: 'Password and confirm password do not match.'
                    })
                } else {
                    this.setState({
                        successMessage: 'New password and confirm password match.'
                    })
                }
            }
        } catch (error) { }
    }


    updateProfile = (event) => {
        try {
            event.preventDefault()
            if (this.state.buttonEnable) {

                if (!this.state.credential.email.trim() || !this.state.credential.shippingAddress.trim() || !this.state.credential.phoneNumber.trim() || !this.state.credential.fullName.trim() || !this.state.credential.city.trim() || !this.state.credential.country.trim()) {
                    this.setState({
                        errorMessage: 'All fields are mendatory.'
                    })
                    return

                }
                const data = this.state.credential

                data.password = ''
                delete data._id
                delete data.status
                data.newPassword = ''
                data.confirmPassword = ''

                this.setState({
                    buttonEnable: false
                })

                axios
                    .put('/profile', data)
                    .then((response) => {

                        toast(response.data.message, { type: 'success', position: 'top-center' });
                        this.setState({ credential: data, buttonEnable: true })

                    }).catch((error) => {
                        this.setState({ errorMessage: error.data.message, buttonEnable: true })
                    })

            } else return
        } catch (error) {
            this.setState({
                buttonEnable: true
            })
        }
    }
    updatePassword = (event) => {
        try {
            event.preventDefault()
            if (this.state.buttonEnable) {

                const data = {
                    newPassword: this.state.credential.newPassword,
                    password: this.state.credential.password,
                    confirmPassword: this.state.credential.confirmPassword,

                }
                if (data.newPassword != data.confirmPassword) {
                    this.setState({
                        errorMessage: 'New passowrd and confirm password must be same.'
                    })
                    return
                }
                if ((!data.newPassword || !data.confirmPassword) && data.password) {
                    this.setState({
                        errorMessage: 'New password and confirm password are required.'
                    })
                    return
                }
                if (!data.password) {
                    this.setState({
                        errorMessage: 'Old password is required.'
                    })
                    return
                }
                this.setState({
                    buttonEnable: false
                })
                axios
                    .put('/password', data)
                    .then((response) => {
                        toast(response.data.message, { type: 'success', position: 'top-center' });

                        const credential = this.state.credential
                        credential.confirmPassword = ""
                        credential.newPassword = ""
                        credential.password = ""
                        this.setState({ successMessage: '', errorMessage: '', credential, buttonEnable: true })

                    }).catch((error) => {
                        toast(error.data.message, { type: 'error', position: 'top-center' })
                        this.setState({ errorMessage: error.data.message, buttonEnable: true, successMessage: '', errorMessage: '' })
                    })

            }
            else return

        } catch (error) {
            this.setState({ errorMessage: error.data.message, successMessage: '', buttonEnable: true })

        }
    }

    render() {
        return (
            <div>
                <Navigation />

                <section className="checkout-section spad">
                    <div className="container">
                        <form className="checkout-form">
                            <div className="row">
                                <div className="col-lg-6">

                                    <h4>Personal Details</h4>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <label for="fir">Full Name<span>*</span></label>
                                            <input type="text" name="fullName" onChange={this.onChangeHandler} value={this.state.credential.fullName} id="fir" />
                                        </div>

                                        <div className="col-lg-12">
                                            <label for="street">Street Address<span>*</span></label>
                                            <input type="text" id="street" name="shippingAddress" onChange={this.onChangeHandler} value={this.state.credential.shippingAddress} className="street-first" />
                                        </div>
                                        <div className="col-lg-6">
                                            <label for="email">City<span>*</span></label>
                                            <input type="text" id="email" name="city" onChange={this.onChangeHandler} value={this.state.credential.city} />
                                        </div>
                                        <div className="col-lg-6">
                                            <label for="phone">Country<span>*</span></label>
                                            <input type="text" id="country" name="country" onChange={this.onChangeHandler} value={this.state.credential.country} />
                                        </div>
                                        <div className="col-lg-6">
                                            <label for="email">Email Address<span>*</span></label>
                                            <input type="text" id="email" name="email" disabled onChange={this.onChangeHandler} value={this.state.credential.email} />
                                        </div>
                                        <div className="col-lg-6">
                                            <label for="phone">Phone<span>*</span></label>
                                            <input type="number" id="phone" name="phoneNumber" onChange={this.onChangeHandler} value={this.state.credential.phoneNumber} />
                                        </div>


                                        <div className="order-btn">
                                            <button type="button" onClick={this.updateProfile} className="site-btn place-btn">Update Profile</button>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <h4>Password</h4>

                                    <div className="row">
                                        <div className="col-lg-12">
                                            <label for="fir">Old password<span>*</span></label>
                                            <input type="password" name="password" onChange={this.onChangeHandler} value={this.state.credential.password} id="fir" />
                                        </div>

                                        <div className="col-lg-12">
                                            <label for="street">New password<span>*</span></label>
                                            <input type="password" id="newPassword" name="newPassword" onChange={this.onChangeHandler} value={this.state.credential.newPassword} className="street-first" />
                                        </div>
                                        <div className="col-lg-12">
                                            <label for="email">Confirm password<span>*</span></label>
                                            <input type="password" id="confirmPassword" name="confirmPassword" onChange={this.onChangeHandler} value={this.state.credential.confirmPassword} />
                                        </div>

                                        <div className="order-btn">
                                            <button type="button" onClick={this.updatePassword} className="site-btn btn-primary">Update Password</button>
                                        </div>
                                        <div className="col-lg-12">

                                            <div style={{ color: 'red' }}>{this.state.errorMessage}</div>
                                            <div style={{ color: 'green' }}>{this.state.successMessage}</div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </form>
                        <ToastContainer />

                    </div>
                </section>
            </div>

        )
    }
}
export default Index