import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import '../../../public/admin/css/bootstrap.min.css';
import './style.css';
import history from '../../middleware/history';
import Axios from '../../middleware/axios';
// import { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      errorMessage: '',
      successMessage: '',
      credential: {
        newPassword: '',
        password: '',
        confirmPassword: '',
      },
      buttonEnable: false
    };
  }

  componentDidMount() {
    const script = document.createElement("script");
    script.src = "https://ajax.googleapis.com/ajax/libs/jquery/1.12.4/jquery.min.js";
    script.async = true;
    document.body.appendChild(script);

    const script2 = document.createElement("script");
    script2.src = "https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/js/bootstrap.min.js";
    script2.async = true;
    document.body.appendChild(script2);

    this.setState({
      buttonEnable: true
    })
  }

  onLogout = (event) => {
    try {
      event.preventDefault()
      Axios
        .post('/logout')
        .then((response) => {
          localStorage.removeItem('login');
          history.push('/admin/login');
          window.location.reload(false)
        })


    } catch (error) { }
  }

  updatePassword = (event) => {
    try {
      event.preventDefault()

      if (this.state.buttonEnable) {

        const data = {
          newPassword: this.state.credential.newPassword,
          password: this.state.credential.password,
          confirmPassword: this.state.credential.confirmPassword,

        }
        if (data.newPassword != data.confirmPassword) {
          this.setState({
            errorMessage: 'New passowrd and confirm password must be same.'
          })
          return
        }
        if ((!data.newPassword || !data.confirmPassword) && data.password) {
          this.setState({
            errorMessage: 'New password and confirm password are required.'
          })
          return
        }
        if (!data.password) {
          this.setState({
            errorMessage: 'Old password is required.'
          })
          return
        }
        this.setState({
          buttonEnable: false
        })

        Axios
          .put('/password', data)
          .then((response) => {
            toast(response.data.message, { type: 'success', position: 'top-center' });

            const credential = this.state.credential
            credential.confirmPassword = ""
            credential.newPassword = ""
            credential.password = ""
            this.setState({ successMessage: '', errorMessage: '', credential, buttonEnable: true })

          }).catch((error) => {
            toast(error.data.message, { type: 'error', position: 'top-center' })
            this.setState({ errorMessage: error.data.message, buttonEnable: true, successMessage: '', errorMessage: '' })
          })

      }
      else return

    } catch (error) {
      this.setState({ errorMessage: error.data.message, successMessage: '', buttonEnable: true })

    }
  }

  onChangeHandler = (event) => {
    this.setState({
      errorMessage: '',
      successMessage: ''
    })
    const credential = this.state.credential
    credential[event.target.name] = event.target.value
    this.setState({
      credential
    })
    if (event.target.name == 'confirmPassword') {
      if (credential.newPassword != credential.confirmPassword) {
        this.setState({
          errorMessage: 'Password and confirm password do not match.'
        })
      } else {
        this.setState({
          successMessage: 'New password and confirm password match.'
        })
      }
    }


  }
  render() {
    return (
      <header id="header">
        <div className="container">
          <div className="row">
            <div className="col-md-10">
              <h1><Link to={'/admin/dashboard'}><span className="glyphicon glyphicon-cog" aria-hidden="true"></span></Link>Dashboard <small>Bridalwear</small></h1>
            </div>
            <div className="col-md-2">
              <div className="dropdown create">
                <button className="btn btn-default dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                  Admin
                </button>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenu1">
                  <li><a type="button" data-toggle="modal" data-target="#" onClick={this.onLogout}>Logout</a></li>
                  <li><a type="button" data-toggle="modal" data-target="#changePassword">Change Password</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="changePassword" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <form>
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                  <h4 className="modal-title" id="myModalLabel" style={{ color: "#333", top: "23px", position: "absolute" }}>Change Password</h4>
                </div>
                <div className="modal-body">
                  <div className="form-group">
                    <label style={{ color: "#333" }}>Current Password</label>
                    <input type="password" name='password' className="form-control" required onChange={this.onChangeHandler} />
                  </div>
                  <div className="form-group">
                    <label style={{ color: "#333" }}>New Password</label>
                    <input type="password" name='newPassword' className="form-control" required onChange={this.onChangeHandler} />
                  </div>
                  <div className="form-group">
                    <label style={{ color: "#333" }}>Confirm Password</label>
                    <input type="password" name='confirmPassword' required className="form-control" onChange={this.onChangeHandler} />
                  </div>
                </div>
                <hr />
                <div className="form-group">
                  <span className='customMessage' style={{ color: "blue", marginLeft: "20px" }} >{this.state.errorMessage}</span>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                  <button type="button" className="btn btn-primary" onClick={this.updatePassword}>Update</button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <ToastContainer />

      </header>


    )
  }
}
export default Header