import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Footer extends Component {
	render() {
		return (
			<footer className="footer-section">
				<div className="container">
					<div className="row">
						<div className="col-lg-3">
							<div className="footer-left">
								<div className="logo">
									<Link to="#"><img src='/img/sm-logo2.png' alt="" />
									</Link>
								</div>
								<ul>
									<li>Phone: +92 302 2060098</li>
									<li>Email: support@Bbridalwearonline.com</li>
								</ul>
								<div className="footer-social">
									<a href="www.facebook.com/bridalwearonline" target={'_blank'}><i className="fa fa-facebook"></i></a>
									<a href="www.instagram.com/bridalwearonline/" target={'_blank'}><i className="fa fa-instagram"></i></a>
									<a href="wa.me/923022060098" target={'_blank'}><i className="fa fa-whatsapp"></i></a>
								</div>
							</div>
						</div>
						<div className="col-lg-2 offset-lg-1">
							<div className="footer-widget">
								<h5>Information</h5>
								<ul>
									<li><Link to="#">About Us</Link></li>
									<li><Link to="/checkout">Checkout</Link></li>
								</ul>
							</div>
						</div>
						<div className="col-lg-2">
							<div className="footer-widget">
								<h5>My Account</h5>
								<ul>
									<li><Link to="/me">My Account</Link></li>
									<li><Link to="/Shoppingcart">Shopping Cart</Link></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div className="copyright-reserved">
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="copyright-text">
									All rights reserved by Bridal wear online
								</div>

							</div>
						</div>
					</div>
				</div>
			</footer>
		)
	}
}
export default Footer