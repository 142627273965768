import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../Header';
import Footer from '../Footer';
import Nav from '../nav';
import Axios from '../../../middleware/axios';
import { useState,useEffect } from 'react';
import OrderDetails from '../orderDetail';

export default function Edit(props) {
	const [APIData, setAPIData] = useState({});

	useEffect( () => {
        Axios.get(`/orders/${props.match.params.id}`)
            .then((response) => {
                setAPIData(response.data.data);
            })
    }, []);

	return (
		<div>
			<Header />
			<section id="main">
				<div className="container">
					<div className="row">
						<Nav />
						<div className="col-md-9">
							<div className="panel panel-default">
								<div className="panel-heading main-color-bg">
									<h3 className="panel-title">Orders</h3>
								</div>
								<div className="panel-body">
									<OrderDetails APIData={APIData}  />
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>		

			<Footer />
		</div>
	)
}
