import React from 'react';
import { useState, useEffect } from 'react';
import Axios from '../../../middleware/axios';
import history from '../../../helpers/history'
import variables from '../../../helpers/variable'

const StockDetail = ({ APIData }) => {
    const [quantity, setQuantity] = useState(0);
    const [price, setPrice] = useState(0);
    const [status, setStatus] = useState('active');
    const [facebookUrl, setFacebookUrl] = useState('');
    const [displayOnSlider, setDisplayOnSlider] = useState(false);
    const StockPictures = () => {
        if (APIData && APIData.pictures) {
            return APIData.pictures.map((el, i) => {
                return (
                    <div className="col">
                        <img src={`${variables.url}${el.pictureUrl}`} />
                    </div>
                )
            })
        }

        else {
            return (<div />)
        }
    }


    useEffect(() => {

        if (APIData) {

            if (APIData.quantity) {
                setQuantity(APIData.quantity);
            }
            if (APIData.facebookUrl) {
                setFacebookUrl(APIData.facebookUrl);
            }
            if (APIData.price) {
                setPrice(APIData.price);
            }
            if (APIData.displayOnSlider)
                setDisplayOnSlider(APIData.displayOnSlider ? displayOnSlider : false);

        }
        const getToken = localStorage.getItem("login")
        if (!getToken) {
            history.push("/admin/login")
            window.location.reload(false)

        }
    }, [APIData]);

    const [errorMessage, setErrorMessage] = useState('');

    const handleQuantityChange = (e) => {
        try {
            setQuantity(e.target.value);
        } catch (error) {
            console.log(error)
        }
    }
    const handlePriceChange = (e) => {
        try {

            setPrice(e.target.value);
        } catch (error) {
            console.log(error)

        }
    }
    const handleSliderChange = (e) => {
        try {
            setDisplayOnSlider(e.target.value)
        } catch (error) {

        }
    }
    const handleStatusChange = (e) => {
        try {
            setStatus(e.target.value);
        } catch (error) {
        }
    }

    const handleFacebookUrlChange = (e) => {
        try {
            setFacebookUrl(e.target.value);
        } catch (error) {
        }
    }



    const updateAPIData = (e) => {
        const changeStatus = { quantity: quantity, price, status, displayOnSlider, facebookUrl };
        Axios.put(`/restocked/${APIData._id}`,
            changeStatus
        ).then((response) => {
            setErrorMessage(response.data.message);
        }).catch((error) => {
            setErrorMessage(error.data.message);
        });
    }


    return (
        <div className="container orderdetailContainer">
            <article className="card">
                <header className="card-header"> Product ID: {APIData._id} </header>
                <form>
                    <div className="card-body">
                        <div className="form-group">
                            <label >Title</label>
                            <input type="text" className="form-control" value={APIData.title} readOnly />
                        </div>
                        <div className="form-group">
                            <label >Facebook Url</label>
                            <input type="text" onChange={handleFacebookUrlChange} className="form-control" value={facebookUrl} />
                        </div>
                        <div className="form-group">
                            <label >Created At</label>
                            <input type="text" className="form-control" value={APIData.createdAt} readOnly />
                        </div>
                        <div className="form-group">
                            <label >Sold</label>
                            <input type="text" className="form-control" value={APIData.sold} readOnly />
                        </div>
                        <div className="form-group">
                            <label >Brand</label>
                            <input type="text" className="form-control" value={APIData.brand} readOnly />
                        </div>
                        <div className="form-group">
                            <label >Type</label>
                            <input type="text" className="form-control" value={APIData.type} readOnly />
                        </div>

                        <div className="form-group">
                            <label >Etag</label>
                            <input type="text" className="form-control" value={APIData.etags} readOnly />
                        </div>
                        <div className="form-group">
                            <label >Price</label>
                            <input type="text" className="form-control" onChange={handlePriceChange} value={price} />
                        </div>
                        <div className="form-group">
                            <label >Quantity</label>
                            <input type="text" className="form-control" value={quantity} onChange={handleQuantityChange} />
                        </div>
                        <div className="form-group">
                            <label >Display on slides</label>
                            <select type="select" onChange={handleSliderChange} value={displayOnSlider} className="form-control" >
                                <option value="">Select Visablity</option>
                                <option value={true}>Yes</option>
                                <option value={false}>No</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label >Status</label>
                            <select onChange={handleStatusChange} value={status}>
                                <option value={'active'}>Active </option>
                                <option value={'archived'}>Archived </option>
                            </select>

                        </div>
                        <hr />

                        <div className="form-group">
                            <div className="card-body row">
                                <StockPictures />
                            </div>

                        </div>
                        <span className='customMessage'>{errorMessage}</span>

                        <hr />
                        <button type='button' className='btn btn-primary' onClick={updateAPIData}>Submit</button>
                        <hr /> <a href="/admin/stocks" className="btn btn-warning" data-abc="true"> <i className="fa fa-chevron-left"></i> Back to Stocks</a>
                    </div>
                </form>
            </article>
        </div>
    )

}

export default StockDetail