import React from 'react';
import Header from '../Header';
import Footer from '../Footer';
import Nav from '../nav';
import Axios from '../../../middleware/axios';
import { useState, useEffect } from 'react';
import StockDetail from './stockDetail';
import history from '../../../helpers/history'

export default function Edit(props) {
	const [APIData, setAPIData] = useState({});

	useEffect(() => {

		const getToken = localStorage.getItem("login")
		if (!getToken) {
			history.push("/admin/login")
			window.location.reload(false)

		}

		Axios.get(`/stocks/${props.match.params.id}`)
			.then((response) => {
				setAPIData(response.data.data);
			});


	}, []);

	return (
		<div>
			<Header />
			<section id="main">
				<div className="container">
					<div className="row">
						<Nav />
						<div className="col-md-9">
							<div className="panel panel-default">
								<div className="panel-heading main-color-bg">
									<h3 className="panel-title">Stock</h3>
								</div>
								<div className="panel-body">
									<StockDetail APIData={APIData} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<Footer />
		</div>
	)
}
