import React, { Component } from 'react'
import '../../public/css/bootstrap.min.css';
import '../../public/css/elegant-icons.css';
import '../../public/css/font-awesome.min.css'
import '../../public/css/jquery-ui.min.css';
import '../../public/css/nice-select.css';
import '../../public/css/owl.carousel.min.css';
import '../../public/css/slicknav.min.css';
import '../../public/css/style.css';
import '../../public/css/themify-icons.css'
import Navigation from './Navigation';
import axios from '../middleware/axios';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import history from '../middleware/history';

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            credential: {
                password: '',
                confirmPassword: '',
            },
            buttonEnable: true,
            errorMessage: '',
            successMessage: '',
            token: ''
        }
    }
    componentDidMount() {
        this.setState({
            token: this.props.match.params.id
        })
    }
    onChangeHandler = (event) => {
        this.setState({
            errorMessage: ''
        })
        const credential = this.state.credential
        credential[event.target.name] = event.target.value
        this.setState({
            credential
        })
        if (event.target.name == 'confirmPassword') {
            if (this.state.credential.password != this.state.credential.confirmPassword) {
                this.setState({
                    errorMessage: 'Password and confirm password do not match.'
                })
            } else {
                this.setState({
                    successMessage: 'Password and confirm password match.'
                })
            }
        }
    }
    setPassword = (event) => {
        try {
            event.preventDefault()
            if (this.state.credential.password.trim() != this.state.credential.confirmPassword.trim()) {
                this.setState({
                    errorMessage: 'Password and confirm password do not match.'
                })
                return
            }
            if (this.state.credential.password.trim().length < 8) {
                this.setState({
                    errorMessage: 'Password should be greater than 8 characters.'
                })
                return
            }
            if (this.state.buttonEnable) {
                this.setState({
                    buttonEnable: false
                })
                const credential = this.state.credential
                credential.newPassword = this.state.credential.password
                credential.token = this.state.token
                axios
                    .post('/set-password', this.state.credential)
                    .then((response) => {
                        toast(response.data.message, { type: 'success' });
                        setTimeout(() => {
                            history.push('/login')
                            window.location.reload()
                        }, 2000)
                    }).catch((error) => {
                        this.setState({ errorMessage: error.data.message })
                    })
                this.setState({
                    buttonEnable: true
                })
            }
        } catch (error) {
        }
    }

    render() {
        return (
            <div>
                <Navigation />

                <div className="register-login-section spad">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 offset-lg-3">
                                <div className="login-form">
                                    <h2>Set Password</h2>
                                    <form >
                                        <div className="group-input">
                                            <label for="pass">Password *</label>
                                            <input type="password" onChange={this.onChangeHandler} id="pass" value={this.state.credential.password} name="password" />
                                        </div>
                                        <div className="group-input">
                                            <label for="con-pass">Confirm Password *</label>
                                            <input type="password" onChange={this.onChangeHandler} id="con-pass" value={this.state.credential.confirmPassword} name="confirmPassword" />
                                        </div>
                                        <div style={{ color: 'red' }}>{this.state.errorMessage}</div>
                                        <div style={{ color: 'green' }}>{this.state.successMessage}</div>
                                        <div className="group-input gi-check">
                                            <div className="gi-more">
                                                <Link to="/login" className="forget-pass">Login</Link>
                                            </div>
                                        </div>
                                        <button type="button" onClick={this.setPassword} className="site-btn login-btn">Set</button>
                                    </form>
                                    <ToastContainer />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        )
    }
}
export default Index