import React, { Component } from 'react'
import Navigation from './Navigation';
import Webfooter from './Footer';
import { Link } from 'react-router-dom';
import variable from '../helpers/variable';



class Shoppingcart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            buyItems: [],
            subTotal: 0
        }
    }


    totalOrderCalculate = () => {
        const copy = [...this.state.buyItems];
        let total = 0;
        if (copy.length) {
            for (let key in copy) {
                let sub = copy[key].price * copy[key].quantity
                total += sub
            }
        }
        this.setState({ subTotal: total }, () => {
            localStorage.setItem("subTotal", JSON.stringify(this.state.subTotal));
        })
    }
    componentDidUpdate(previousProps, previousState) {
        if (previousState.buyItems !== this.state.buyItems) {
            this.totalOrderCalculate()
        }
    }
    plus = (item, key) => {
        const copy = [...this.state.buyItems];
        if (copy[key].quantity < 5) {
            copy[key].quantity += 1;
            this.setState({ buyItems: copy }, () => {
                localStorage.setItem("orders", JSON.stringify(this.state.buyItems));
            });
        }

    }
    minus = (item, key) => {
        const copy = [...this.state.buyItems];
        if (copy[key].quantity > 1) {
            copy[key].quantity -= 1;
            this.setState({ buyItems: copy }, () => {
                localStorage.setItem("orders", JSON.stringify(this.state.buyItems));
            });
        }
    }

    componentDidMount() {
        const getFromLocalStroage = localStorage.getItem('orders')
        const getFromLocalStroageSubTotal = localStorage.getItem('subTotal')
        const prod = JSON.parse(getFromLocalStroage)
        const subTotals = JSON.parse(getFromLocalStroageSubTotal)
        if (prod) {
            this.setState({ buyItems: prod })
            this.setState({ subTotal: subTotals })
        }
    }

    removetocartProduct = (event) => {
        let itemsArray = JSON.parse(localStorage.getItem("orders"))
        let newItemsArray = itemsArray.splice(itemsArray.indexOf(event), 1)
        if (newItemsArray.lenght !== undefined) {
            localStorage.setItem('orders', JSON.stringify(newItemsArray));
            this.setState({ buyItems: newItemsArray })
        } else {
            this.setState({ buyItems: [] })
            localStorage.removeItem('orders')
            localStorage.removeItem('subTotal')
        }
        this.totalOrderCalculate()
    }

    render() {
        const CartItems = ({ item, i }) => {
            let imgUrl = item && item.imageUrl ? item.imageUrl : '/img/no-image.jpg';
            return (
                <div key={i} className="col-lg-12 col-md-12 col-sm-12 col-xs-12" >
                    <div className="col-lg-2 col-md-3 col-sm-2 col-xs-12" >
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12" style={{ height: "calc(27vh)", }}>
                            <img style={{ width: '100%', height: '100%', objectFit: 'contain' }} src={`${variable.url}${imgUrl}`} alt="" />
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12" style={{ margin: "12px auto" }} >
                        <span style={{ fontSize: "1.3em", fontWeight: 600, color: "gray" }}>{item.title}</span>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-1 col-xs-12" style={{ margin: "12px auto" }} >
                        <span style={{ fontSize: "1.1em", fontWeight: 600, color: "#e7ab3c" }}>Rs. {item.price}/=</span>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-2 col-xs-12" style={{ display: 'flex', flexDirection: "row", }}>
                        <button type="button" className="btn btn-defualt" onClick={() => { this.minus(item, i) }}>
                            <i className="glyphicon glyphicon-minus"></i>
                        </button>
                        <button type="button" disabled={true} className="btn btn-defualt">
                            <span>{item.quantity}</span>
                        </button>
                        <button type="button" className="btn btn-defualt" onClick={() => { this.plus(item, i) }}>
                            <i className="glyphicon glyphicon-plus"></i>
                        </button>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-1 col-xs-12" style={{ margin: "12px auto" }} >
                        <span style={{ fontSize: "1.1em", fontWeight: 600, color: "#e7ab3c" }}>Rs. {item.quantity * item.price}/=</span>
                    </div>
                    <div className="col-lg-1 col-md-1 col-sm-1 col-xs-12" style={{ margin: "12px auto", display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }} >
                        <button className="btn btn-danger" onClick={() => { this.removetocartProduct(item._id) }} value={item._id}>
                            <i className="glyphicon glyphicon-trash"></i>
                        </button>
                    </div>
                </div>
            )
        }

        const CartItemsTotals = ({ total }) => {
            return (
                <div className="row">
                    <div className="col-lg-4">
                    </div>
                    <div className="col-lg-4 offset-lg-4">
                        <div className="proceed-checkout">
                            <ul>
                                <li className="subtotal">Subtotal <span>{this.state.subTotal}</span></li>
                                <li className="cart-total">Total <span>{this.state.subTotal}</span></li>
                            </ul>
                            <Link to="/checkout" className="proceed-btn">PROCEED TO CHECK OUT</Link>
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div>
                <Navigation />
                {/* modified product list headings*/}
                <div className="container" style={{ marginTop: "5%" }}>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="col-lg-2 col-md-3 col-sm-2 col-xs-2" >
                            <span style={{ fontSize: "1.5em", fontWeight: 600, color: "gray", }}>Images</span>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-2"  >
                            <span style={{ fontSize: "1.5em", fontWeight: 600, color: "gray", }}>Product Name</span>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-1 col-xs-2" >
                            <span style={{ fontSize: "1.5em", fontWeight: 600, color: "gray", }}>Price</span>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2" >
                            <span style={{ fontSize: "1.5em", fontWeight: 600, color: "gray", }}>Quantity</span>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-1 col-xs-2" >
                            <span style={{ fontSize: "1.5em", fontWeight: 600, color: "gray", }}>Total</span>
                        </div>
                        <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1" >
                            <span style={{ fontSize: "1.5em", fontWeight: 600, color: "gray", }}>Option</span>
                        </div>
                    </div>
                </div>
                {/* modified product list content*/}
                <div className="container" style={{ marginTop: "2%", marginBottom: "5%" }}>
                    {
                        this.state.buyItems === null ? (
                            <span>No items in your cart</span>
                        ) :
                            this.state.buyItems.map((item, i) => (
                                <CartItems item={item} i={i} />
                            ))
                    }
                </div>
                <div className="container" style={{ marginTop: "2%", marginBottom: "5%" }}>
                    {
                        this.state.buyItems != null
                            ?
                            <CartItemsTotals />
                            :
                            <span></span>
                    }
                </div>

                {/* <section className="shopping-cart spad">
                    <div className="container">

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="cart-table">
                                    <table>
                                        {
                                            this.state.buyItems != null
                                                ?
                                                <thead>
                                                    <tr>
                                                        <th>Image</th>
                                                        <th className="p-name">Product Name</th>
                                                        <th>Price</th>
                                                        <th>Quantity</th>
                                                        <th>Total</th>
                                                        <th><i className="ti-close"></i></th>
                                                    </tr>
                                                </thead>
                                                :
                                                <span></span>
                                        }
                                        <tbody>
                                            {
                                                this.state.buyItems == null
                                                    ?
                                                    <span>No items in your cart</span>
                                                    :
                                                    this.state.buyItems.map((item, i) => (
                                                        <CartItems item={item} key={i} />
                                                    ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                {
                                    this.state.buyItems != null
                                        ?
                                        <CartItemsTotals />
                                        :
                                        <span></span>
                                }

                            </div>
                        </div>
                    </div>
                </section> */}

                <Webfooter />
            </div>

        )
    }
}
export default Shoppingcart